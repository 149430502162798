import clsx from 'clsx';

import styles from './SkeletonItem.module.scss';

interface IProps {
  className?: string;
}

const SkeletonItem = ({ className }: IProps) => {
  return <div className={clsx(styles.container, className)} />;
};

export default SkeletonItem;
