import clsx from 'clsx';
import { useState } from 'react';
import { ReactComponent as Close } from 'Assets/icons/remove-icon.svg';
import { ReactComponent as CheckMark } from 'Assets/icons/checkMark.svg';
import { ReactComponent as FolderIcon } from 'Assets/icons/folder.svg';

import styles from './UploadCsv.module.scss';

interface IProps {
  setFile: (file: File | undefined) => void;
  file?: File;
}

const UploadCsv = ({ setFile, file }: IProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const items = e.dataTransfer.items;
    if (items && items.length > 0) {
      const item = items[0];
      if (item.kind === 'file' && item.type === 'text/csv') {
        setIsDragging(true);
      }
    }
  };

  const handleDragLeave = () => {
    if (isDragging) setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0] && files[0].type === 'text/csv') {
      setFile(files[0]);
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Please Note: only CSV files are supported.
      </div>
      <div
        className={clsx(styles.browseFile, {
          [styles.activeImageContent]: isDragging,
        })}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className={styles.draggableContent}>
          {!file ? (
            <>
              <div className={styles.text}>
                Drag and drop file or click to browse
              </div>
              <label htmlFor="browse">
                <div className={styles.browseButton}>
                  <FolderIcon /> Browse
                </div>
              </label>
            </>
          ) : (
            <>
              <div className={styles.checkMark}>
                <CheckMark />
              </div>
              <div className={styles.text}>{file?.name} uploaded!</div>
              <div className={styles.remove} onClick={() => setFile(undefined)}>
                <Close className={styles.closeIcon} />
                <span className={styles.text}>Remove</span>
              </div>
            </>
          )}
        </div>
      </div>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        id="browse"
      />
    </div>
  );
};

export default UploadCsv;
