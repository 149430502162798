import {
  APPS_NAMES_FETCHED,
  AUTOSASS_DESIGN_FETCHED,
  DELETE_AUTOSASS_APP,
  GET_AUTOSASS_APPS,
  SET_AUTOSAAS_LOGO,
  UPDATE_AUTOSASS_APP,
} from './autosaasActions';
import { PayloadAction } from '@reduxjs/toolkit';

export interface IAppsNames {
  key: string;
  name: string;
}

export interface IAutoSassDesign {
  domain: string;
  name: string;
  logoUrl: string;
  helpCenterUrl?: string;
}

export interface ISubAccountsState {
  logoUrl: string;
  appsNames?: IAppsNames[];
  autoSassDesign?: IAutoSassDesign;
  autoApps?: IAutoApp[];
}

export interface IAutoApp {
  _id: string;
  name: string;
  description?: string;
  iconUrl?: string;
  template?: {
    _id: string;
    layout: any;
  };
}

const url = window.location.href;
const hostname = new URL(url).hostname;
const isAutoFunnel =
  hostname === 'localhost' ||
  hostname === 'app.dev.autofunnel.ai' ||
  hostname === 'app.autofunnel.ai';
const domain = isAutoFunnel ? 'app.autofunnel.ai' : hostname;

const initialState: ISubAccountsState = {
  logoUrl: `https://cdn.autofunnel.ai/autosaas/${domain}/logo?${new Date().getTime()}`,
};

const autosaasReducer = (
  state: ISubAccountsState = initialState,
  action: PayloadAction<
    string | IAppsNames[] | IAutoSassDesign | IAutoApp[] | IAutoApp
  >
): ISubAccountsState => {
  switch (action.type) {
    case SET_AUTOSAAS_LOGO:
      return {
        ...state,
        logoUrl: action.payload as string,
      };
    case APPS_NAMES_FETCHED:
      return {
        ...state,
        appsNames: action.payload as IAppsNames[],
      };
    case AUTOSASS_DESIGN_FETCHED:
      return {
        ...state,
        autoSassDesign: action.payload as IAutoSassDesign,
      };
    case GET_AUTOSASS_APPS:
      return {
        ...state,
        autoApps: action.payload as IAutoApp[],
      };
    case DELETE_AUTOSASS_APP:
      return {
        ...state,
        autoApps: state.autoApps?.filter(
          (item) => item._id !== (action.payload as string)
        ),
      };
    case UPDATE_AUTOSASS_APP:
      return {
        ...state,
        autoApps: state.autoApps?.map((item) =>
          item._id === (action.payload as IAutoApp)._id
            ? { ...item, ...(action.payload as IAutoApp) }
            : item
        ),
      };

    default:
      return state;
  }
};

export { initialState, autosaasReducer };
