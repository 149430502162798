import Button from 'Styled/Button1';
import s from './CopyCraft.module.scss';

interface IProps {
  onAddClicked: () => void;
  pageName: string;
}

const CopyCraftEmptyPage = (props: IProps) => {
  return (
    <div className={s.emptyTopicContent}>
      <h1 className={s.emptyTextTitle}>Welcome to {props.pageName}</h1>
      <p>Please create new project to start</p>
      <Button
        onClick={() => props.onAddClicked()}
        className={s.emptyTextButton}
      >
        Create Project
      </Button>
    </div>
  );
};

export default CopyCraftEmptyPage;
