import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IBroadcast } from 'types';
import { graphQlCall } from 'graphql/utils';
import clsx from 'clsx';
import queries from 'graphql/queries';
import BroadcastSkeleton from 'Components/Skeletons/BroadcastSkeleton/BroadcastSkeleton';
import BroadcastItem from 'Components/Broadcast/BroadcastItem/BroadcastItem';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import Pagination from 'UILib/Pagination/Pagination';

import styles from './Broadcasts.module.scss';

const sortCriteria = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

const Broadcasts = () => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [broadcasts, setBroadcasts] = useState<IBroadcast[]>([]);
  const [broadcastsStats, setBroadcastsStats] = useState<
    { delivered: number; opened: number; clicked: number }[]
  >([]);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const getBroadCasts = () => {
    graphQlCall({
      queryTemplateObject: queries.GET_BROADCASTS_WITH_PAGINATION_QUERY,
      headerType: 'USER-AUTH',
      values: {
        skip: (currentPage - 1) * LIMIT,
        limit: LIMIT,
        search: searchTerm,
        sortBy,
        sortAsc: sortCriteria.find((item) => item.value === sortBy)?.sortAsc,
      },
    })
      .then((data) => {
        setTotalCount(data.total);
        setBroadcasts(data.broadcasts || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBroadCasts();
  }, [searchTerm, sortBy, currentPage]);

  useEffect(() => {
    if (broadcasts.length === 0) return;

    graphQlCall({
      queryTemplateObject: queries.GET_BROADCASTS_STATISTICS,
      values: { ids: broadcasts.map((item) => item._id) },
      headerType: 'USER-AUTH',
    }).then(setBroadcastsStats);
  }, [broadcasts]);

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.contentContainer, {
          [styles.loaderContainer]: loading,
        })}
      >
        {loading ? (
          <BroadcastSkeleton />
        ) : (
          <>
            <DashboardHeader
              total={totalCount + ' Broadcasts'}
              title="Your Broadcasts"
              buttonText="New Broadcast"
              handleAddNewItem={() =>
                history.push('/console/broadcasts/create')
              }
              handleSearch={setSearchTerm}
              handleSort={setSortBy}
              sortOptions={sortCriteria}
              sortBy={sortBy}
            />
            <div className={styles.broadcastElements}>
              {broadcasts.map((item, index) => (
                <BroadcastItem
                  key={item._id}
                  item={item}
                  stats={broadcastsStats[index] ?? {}}
                  fetchBroadcasts={getBroadCasts}
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalCount / LIMIT)}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Broadcasts;
