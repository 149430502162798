import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { Slate, Editable, withReact, RenderElementProps } from 'slate-react';
import {
  Transforms,
  createEditor,
  Descendant,
  Editor,
  Range,
  BaseRange,
} from 'slate';
import { withHistory } from 'slate-history';
import { TagElement } from 'Editors/types';
import TagSelector from './TagSelector/TagSelector';

import styles from './NodeTextEditor.module.scss';

interface IProps {
  tagsList: string[];
  initialValue: Descendant[];
  editorClassName?: string;
  onChange: (newValue: Descendant[]) => void;
}

const withEmbeds = (editor: Editor) => {
  const { isVoid, isInline } = editor;

  editor.isVoid = (element) =>
    element.type === 'tag' || element.type === 'audio' ? true : isVoid(element);
  editor.isInline = (element) =>
    element.type === 'tag' ? true : isInline(element);

  return editor;
};

const NodeTextEditor = ({
  tagsList,
  initialValue,
  editorClassName,
  onChange,
}: IProps) => {
  const [tagTargetRange, setTagTargetRange] = useState<
    BaseRange & { [key: string]: unknown }
  >();
  const [tagSearchString, setTagSearchString] = useState('');
  const [tagSearchIndex, setTagSearchIndex] = useState(0);

  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );

  const editor = useMemo(() => {
    return withEmbeds(withHistory(withReact(createEditor())));
  }, []);

  const validTags = () => {
    return tagsList
      .filter((tag) =>
        tag.toLowerCase().startsWith(tagSearchString.toLowerCase())
      )
      .slice(0, 10);
  };

  const handleTagSelect = (tagIndex: number) => {
    if (!tagTargetRange) return;

    const tags = validTags();
    const mention: TagElement = {
      type: 'tag',
      value:
        tags.length && tagIndex < tags.length
          ? tags[tagIndex]
          : tagSearchString,
      children: [{ text: '' }],
    };
    Transforms.select(editor, tagTargetRange);
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
    setTagTargetRange(undefined);
  };

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(e) => {
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const currentPoint = Editor.point(editor, start);
          const currentWord = Editor.before(editor, currentPoint);
          const currentRange =
            currentWord && Editor.range(editor, currentWord, start);
          const currentText =
            currentRange && Editor.string(editor, currentRange);
          if (currentText?.startsWith('#')) {
            setTagTargetRange(Editor.range(editor, currentWord!, start));
            setTagSearchString('');
            setTagSearchIndex(0);
            return;
          } else {
            const wordBefore = Editor.before(editor, start, { unit: 'word' });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const beforeRange = before && Editor.range(editor, before, start);
            const beforeText =
              beforeRange && Editor.string(editor, beforeRange);
            const beforeHashTagMatch =
              beforeText && beforeText.match(/^#(\w+)$/);
            if (beforeText?.trim().startsWith('#')) {
              setTagTargetRange(Editor.range(editor, before!, start));
              setTagSearchString(
                beforeHashTagMatch ? beforeHashTagMatch[1] || '' : ''
              );
              setTagSearchIndex(0);
              return;
            }
          }
          setTagTargetRange(undefined);
        }
        if (!editor.operations.every((op) => op.type === 'set_selection'))
          onChange(e);
      }}
    >
      <Editable
        renderElement={renderElement}
        className={clsx(styles.editor, editorClassName)}
        onKeyDown={(event) => {
          if (tagTargetRange) {
            if (event.key === 'Escape') {
              event.preventDefault();
              setTagTargetRange(undefined);
            }
            if (event.key === 'ArrowDown') {
              event.preventDefault();
              setTagSearchIndex(tagSearchIndex + 1);
            }
            if (event.key === 'ArrowUp') {
              event.preventDefault();
              setTagSearchIndex(tagSearchIndex - 1);
            }
            if (event.key === 'Enter' || event.key === 'Tab') {
              event.preventDefault();
              handleTagSelect(tagSearchIndex);
            }
          }
        }}
        spellCheck
      />
      <TagSelector
        tags={validTags()}
        editor={editor}
        targetRange={tagTargetRange}
        search={tagSearchString}
        index={tagSearchIndex}
        onTagSelect={handleTagSelect}
      />
    </Slate>
  );
};

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'paragraph':
      return (
        <p className={styles.paragraph} {...attributes}>
          {children}
        </p>
      );
    case 'tag':
      return (
        <span className={styles.tag}>
          #{element.value}
          {children}
        </span>
      );
    default:
      return null;
  }
};

export default NodeTextEditor;
