import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { IGenerationTemplate } from 'types';
import { GenerationItemCardType } from '../../Components/GenerationTemplate/GenerationTemplateCard/GenerationTemplateCard';
import queries from 'graphql/queries';
import GenerationTemplateCard from 'Components/GenerationTemplate/GenerationTemplateCard/GenerationTemplateCard';
import BooksSkeleton from 'Components/Skeletons/BooksSkeleton/BooksSkeleton';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import Pagination from 'UILib/Pagination/Pagination';

import styles from './GenerationTemplates.module.scss';

const sortCriteria = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'Template Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

const defaultTemplateActionSet = {
  id: 'buwgf5hfj',
  type: 'UserInput',
  forms: [
    {
      type: 'Info',
      variables: [],
      name: 'Questions',
    },
  ],
  preFunctions: [],
  postFunctions: [],
};
const Templates = () => {
  const [generationTemplates, setGenerationTemplates] = useState<
    IGenerationTemplate[]
  >([]);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const history = useHistory();

  const getGenerationTemplates = () => {
    graphQlCall({
      queryTemplateObject:
        queries.GET_GENERATION_TEMPLATES_QUERY_WITH_PAGINATION,
      headerType: 'USER-AUTH',
      values: {
        type: 'email',
        skip: (currentPage - 1) * LIMIT,
        limit: LIMIT,
        search: searchTerm,
        sortBy,
        sortAsc: sortCriteria.find((item) => item.value === sortBy)?.sortAsc,
      },
    })
      .then((item) => {
        setGenerationTemplates(item.templates || []);
        setTotalCount(item.total);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateTemplate = async () => {
    try {
      graphQlCall({
        queryTemplateObject: queries.CREATE_GENERATION_TEMPLATE_MUTATION,
        values: {
          name: 'New Template',
          type: 'email',
          actions: JSON.stringify(defaultTemplateActionSet),
        },
        headerType: 'USER-AUTH',
      }).then((template) => {
        history.push(
          `/console/generationTemplate/${template._id}/edit?editor=layout`
        );
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGenerationTemplates();
  }, [searchTerm, sortBy, currentPage]);

  const handleTemplateClick = (senderId: String) => {
    history.push(`/console/generationTemplate/${senderId}/edit?editor=layout`);
  };

  const handleDelete = (id: string) => {
    setGenerationTemplates(
      generationTemplates.filter((item) => item._id !== id)
    );
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>
          <BooksSkeleton />
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <DashboardHeader
            total={totalCount + ' Templates'}
            title="Your Templates"
            buttonText="New Template"
            handleAddNewItem={handleCreateTemplate}
            handleSearch={setSearchTerm}
            handleSort={setSortBy}
            sortOptions={sortCriteria}
            sortBy={sortBy}
          />
          <div className={styles.generationTemplateElements}>
            {generationTemplates.map((item, index) => (
              <GenerationTemplateCard
                key={item._id}
                type={GenerationItemCardType.Template}
                item={item}
                onClick={handleTemplateClick}
                onDelete={() => handleDelete(item._id)}
              />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(totalCount / LIMIT)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
};

export default Templates;
