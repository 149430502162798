import styles from './CircleLoader.module.scss';

interface IProps {
  size?: number;
  color?: string;
}

const CircleLoader = ({ size = 40, color = '#000000' }: IProps) => (
  <svg
    className={styles.spinner}
    width={size}
    height={size}
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className={styles.path}
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
      stroke={color}
    ></circle>
  </svg>
);

export default CircleLoader;
