export const SEND_EMAIL = {
  operationName: 'mailGunSendMessage',
  query: `mutation mailGunSendMessage(
  <KEYS>
      ) {
        mailGunSendMessage(
          <VALUES>  
          ) {
          id
          message
      }
    }`,
};
