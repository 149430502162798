import { EpisodeStatus } from 'Pages/PodcastGenerator/constants';
import { Descendant } from 'slate';

export interface IPage {
  _id: string;
  name: string;
  image: string;
  enabled: boolean;
  exitpopupdata: any;
  exitpopupenabled: boolean;
}

export interface IProduct {
  name: String;
  descriptor: String;
  price: Number;
  currency: String;
  isSubscription: Boolean;
  isSevenDayTrial: Boolean;
}

export interface IUser {
  _id: string;
  name: string;
  owner: string;
  email: string;
  password: string;
  role: string;
  subdomain: string;
  customData: any;
}

export interface IGeneralProject {
  _id: string;
  publicId: string;
  name: string;
  description: string;
  title: string;
  url: string;
  archived: boolean;
  mailchimpAPI: string;
  mailchimpUsername: string;
  domain: string;
  certificateArn: string;
  headerScript: string;
  footerScript: string;
  stripePublicKey: string;
  stripeSecretKey: string;
  user: IUser;
  createdAt: string;
  order: IOrder[];
  products: IProduct[];
  pages: IFunnel[];
  timeStamp?: number;
  ownerId: string;
  customSubDomain: string;
  position: number;
  thumbnail?: string;
}
export interface IDuplicateProjectNameAction {
  funnel: IGeneralProjectName;
}
export interface IGeneralProjectName {
  _id: string;
  name: string;
  position: number;
  domain: string;
}

export interface ICategory {
  _id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITag {
  _id: string;
  name: string;
}

export interface IFunnel {
  _id: string;
  id: string;
  projectId: string;
  name: string;
  enabled: boolean;
  visited: boolean;
  conversion: number;
  archived: boolean;
  status?: string;
  pages: IPage[];
  general: IGeneralProject;
  headerScript: string;
  footerScript: string;
  order: IOrder[];
  createdAt: Date;
  updatedAt: Date;
  thumbnail?: string;
  tags: ITag[];
}

export interface ITemplateItem {
  _id: string;
  name: string;
  description: string;
  image: string;
  bigImage: string;
  smallImage: string;
  schemeImage: string;
  pages: IPage[];
  createdAt: Date;
  updatedAt: Date;
  category: string;
  funnel?: string;
  page?: string;
  type: string;
}

export interface IOrder {
  id: string;
  enabled: boolean;
  previous?: string;
  parent?: string;
  next?: string;
}

export interface IQueueAction {
  type: string;
  params: any;
}

export interface IQueue {
  queueId: string;
  type: string;
  params: any;
  action: IQueueAction;
}

export interface IStripeSubscription {
  name: string;
  priceId: string;
}

export interface ISection {
  _id: string;
  name: string;
  image: string;
  data: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDividerMenuItem {
  type: 'devider';
  label: string;
}

export interface ISectionMenuItem {
  type: 'section';
  name: string;
  image?: string;
  section: string;
}

export interface ISectionSubMenuItem {
  type: 'menu';
  name: string;
  image?: string;
  onlyRegular?: boolean;
  items: IMenuItem[];
  createdAt?: Date;
  updatedAt?: Date;
}

export type IMenuItem =
  | IDividerMenuItem
  | ISectionMenuItem
  | ISectionSubMenuItem;

export interface ISectionMenu {
  name: string;
  items: IMenuItem[];
  createdAt: Date;
  updatedAt: Date;
}

export type AlertType = 'success' | 'info' | 'warning' | 'error';

export interface IAlert {
  type: AlertType;
  text: string;
  description: string;
}

export interface IDraw {
  projectId: string;
  nodes: any[];
}

export interface DNSRecord {
  name: string | null;
  priority: string | null;
  record_type: string;
  valid: boolean;
  value: string;
}

export interface IDomain {
  _id: string;
  name: string;
  userId: string;
  projectId?: string;
  status: string;
  dnsRecords: DNSRecord[];
}

export interface IUserCustomData {
  mailchimpApiKey?: string;
  notes?: string;
}
export interface IUserDetails {
  _id: string;
  email: string;
  stripePublicKey: string;
  stripeSecretKey: string;
  customData: IUserCustomData;
  aiCredits?: number;
  scopes?: string[];
  plans?: string[];
  owner?: string;
  name?: string;
  paymentMethod?: any;
}
export interface IAgencyDetails {
  _id: string;
  email: string;
  stripePublicKey: string;
  stripeSecretKey: string;
  customData: IUserCustomData;
  saasId: string | null;
  aiCredits?: number;
  scopes?: string[];
  plans?: string[];
  owner?: string;
  name?: string;
  paymentMethod?: any;
  thumbnail?: string;
}

export interface IUserAccess {
  autosaas: boolean;
  agency: boolean;
  bookle: boolean;
  copyCraft: boolean;
  funnel: boolean;
  contact: boolean;
  podcast: boolean;
}

export interface QueryObject {
  operationName: string;
  query: string;
  variables?: any;
}

export type GraphQlHeadersType = 'USER-AUTH' | 'AGENCY_AUTH';
export interface IUserSubscriptionPlanData {
  price: string;
  planType: string;
  planInterval: string;
}

export interface IChapter {
  title: string;
  text: string;
  comments: string[];
}
export interface IBook {
  _id: string;
  title?: string;
  description?: string;
  tone?: string;
  audience?: string;
  structure?: string;
  createdAt: number;
  updatedAt?: number;
  chapters?: IChapter[];
  previousResponse?: string;
  images?: string[];
  data?: any;
  coverImageUrl?: string;
  pdfFile?: string;
  progress?: number;
  owner: any;
  titles?: string[];
  coverData?: any;
}

export type TemplateType = 'template' | 'aipage';
export interface ITemplate {
  _id: string;
  type: TemplateType;
  name: string;
  image: string;
  category: string;
  funnel?: string;
  page?: string;
}

export interface ITopic {
  _id: string;
  name: string;
  createdAt: number;
  updatedAt: number;
  type?: string;
  prompt?: string;
}

export interface ITopicValues {
  name: string;
}

export interface IAiRequest {
  _id: string;
  request: string;
  requestType: string;
  response: string;
  hasLiked: number;
  createdAt: number;
  updatedAt: number;
}

export interface IAiResponse {
  request: string;
  requestType: string;
  response: string;
  hasLiked: number;
}
export interface IDuplicatePageOtherAccountPayload {
  pageId: string;
  funnelId: string;
  targetAccountOwner: string;
  sourceAccountOwner: string;
}

export interface IDuplicateFunnelOtherAccountPayload {
  funnelId: string;
  targetAccountOwner: string;
  sourceAccountOwner: string;
}

export interface IAddPageFromAiPayload {
  funnelId: string;
  pagesInfo: any;
}

export interface IUserProduct {
  _id?: string;
  name: string;
  description: string;
  price?: number;
  currency?: string;
}

export interface ISender {
  _id: string;
  name: string;
  email: string;
  status: string;
  mailDomain?: {
    _id: string;
  };
}

export interface IPodcast {
  _id: string;
  name: string;
  image?: string;
  colorsSchemeType: string; // used for storing previously selected colorScheme for Episode Cover
  templateType: string; // used for storing previously selected colorScheme for Episode Cover
  coverData: any; // DEPRICATED
  hostName: string;
  episodesCount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IPodcastWithTotal {
  podcasts: IPodcast[];
  total: number;
}

export interface IPodcastEpisodeSection {
  _id: string;
  index: number;
  title: string;
  notes: string;
  text: string;
  type: string;
  state: string;
}

export interface IPodcastEpisode {
  _id: string;
  name: string;
  tone: string;
  voice: string;
  prompt: string;
  text: string;
  audioUrl?: string;
  coverData: any;
  image: string;
  sections: IPodcastEpisodeSection[];
  status: EpisodeStatus;
  music: {
    id: string;
    introUrl: string;
    outroUrl: string;
    transitionUrl: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface IGenerationTemplate {
  _id: string;
  actions?: {};
  layout?: [];
  name?: string;
  thumbnail?: string;
  createdAt: string;
  updatedAt: string;
}

export interface IGenerationTask {
  _id: string;
  layout?: [];
  name?: string;
  status: string;
  thumbnail?: string;
  createdAt: string;
  updatedAt: string;
}

export interface IBookChapter {
  title: string;
  text?: string;
  comments: string[];
  blockId?: number;
}

export interface PageContainerState {
  pl: string | number;
  pt: string | number;
  pr: string | number;
  mt: string | number;
  ml: string | number;
  mb: string | number;
  mr: string | number;
}

export interface ISmartList {
  _id: string;
  name: string;
}

export enum AlignmentEnum {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface BookleTemplateBlock {
  id: string;
  type: string;
  items?: BookleTemplateBlock[];
  image?: string;
  error?: string;
  text?: Descendant[];
  variable?: string | null;
  generating?: boolean;
  htmlFormat?: string;
  color?: string;
  hasColor?: boolean;
  hasSpacing?: boolean;
  alignment?: AlignmentEnum;
  url?: string;
  isSpacer?: boolean;
  customHeight?: boolean;
  isLoading?: boolean;
  AIImages?: string[];
  imagePreview?: string;
  imagePrompt?: string;
  styles?: {
    width?: number | string;
    height?: number | string;
    borderRadius?: number | string;
    backgroundColor?: string;
    blockSpacing: {
      paddingLeft?: number | string;
      paddingRight?: number | string;
      paddingTop?: number | string;
      paddingBottom?: number | string;
      marginTop?: number | string;
      marginBottom?: number | string;
      marginRight?: number | string;
      marginLeft?: number | string;
    };
    [key: string]: any;
  };
  [key: string]: any;
}

export interface IBroadcast {
  _id?: string;
  name: string;
  image?: string;
  sender?: ISender;
  smartList?: ISmartList;
  status: string;
  sendAt: Date;
  mailDraft: {
    _id: string;
    generationTask: {
      _id: string;
      status: string;
    };
  }
}

export interface ITemplateCategory {
  _id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface IFunnelTemplate {
  _id: string;
  category: string | null;
  type: string;
  enabled: boolean;
  image: string;
  name: string;
  page: string;
}

export type TemplateForm = {
  name: string;
  type: string;
  variables: {
    id: string;
    type: string;
    label: string;
    options: string[];
  }[];
};
