import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import { fetchUserDetails } from 'store/user/userActions';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import { Preview2HTML } from 'Pages/PageGenerationEditor/Draggable/utils';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { ReactComponent as Success } from 'Assets/icons/conversionsIconLight.svg';
import { BookleTemplateBlock, IUserDetails } from 'types';
import { EMAIL_DOMAIN } from 'Constants';
import queries from 'graphql/queries';
import Popup from 'UILib/Popup/Popup';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';

import styles from './EmailTestModal.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  userDetails: IUserDetails;
  fetchUserDetails: () => void;
  templateBlocks: BookleTemplateBlock[];
  templateBlockStyles: IBookleTemplateBlockStyles;
  emailSubject: string;
  htmlScript?: string;
  senderId?: string;
}

const EmailTestModal = ({
  open,
  onClose,
  userDetails,
  fetchUserDetails,
  templateBlocks,
  templateBlockStyles,
  emailSubject,
  htmlScript,
  senderId,
}: IProps) => {
  const [email, setEmail] = useState<string>('');
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => {
    if (!userDetails._id) {
      fetchUserDetails();
    }
  }, [userDetails, fetchUserDetails]);

  const handleSubmitEmail = async () => {
    try {
      if (!!email.length) {
        setIsSending(true);
        const convertor = new Preview2HTML();
        const blocksHtmlContent =
          htmlScript ??
          convertor.setCorrespondingBlocks(
            templateBlocks,
            templateBlockStyles,
            false
          );

        await graphQlCall({
          queryTemplateObject: queries.SEND_EMAIL,
          values: {
            to: email,
            subject: emailSubject,
            text: '',
            senderId: senderId,
            html: blocksHtmlContent,
          },
          headerType: 'USER-AUTH',
        });
        setIsEmailSent(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const handleClose = () => {
    setIsEmailSent(false);
    setEmail('');
    onClose();
  };

  const sendEmailForm = () => {
    return (
      <div className={clsx(styles.emailForm, styles.form)}>
        <Email className={styles.emailIcon} />
        <div className={styles.title}>Test Email</div>
        <div className={styles.subtitle}>
          Enter the email address where you'd like to receive the test email:
        </div>
        <Input
          type="email"
          border="stroke"
          className={styles.emailInput}
          placeholder="Test email"
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!senderId && (
          <div className={styles.info}>
            A test email will be sent via quicksend.ai domain.
          </div>
        )}
        <Button
          className={styles.submitButton}
          onClick={handleSubmitEmail}
          disabled={isSending}
        >
          Send Test <Email />
        </Button>
      </div>
    );
  };

  const successForm = () => {
    return (
      <div className={clsx(styles.successForm, styles.form)}>
        <Success className={styles.successIcon} />
        <div className={styles.title}>Test Email</div>
        <div className={styles.subtitle}>
          Preview the email just sent to your email address:
        </div>
        <div className={styles.emailContainer}>{email}</div>
        <div className={styles.subtitle}>Open it and check the email.</div>
        <Button onClick={handleClose} className={styles.backButton} height={40}>
          Back to Editor
        </Button>
      </div>
    );
  };

  if (!open) return null;

  return (
    <Popup onClose={handleClose} wrapperClassName={styles.wrapper}>
      <div className={styles.container}>
        {!isEmailSent ? sendEmailForm() : successForm()}
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  fetchUserDetails: () => fetchUserDetails(),
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTestModal);
