import React, { useEffect, useState } from 'react';
import {
  Editor,
  Transforms,
  Element as SlateElement,
  BaseSelection,
  Text,
} from 'slate';
import { Tooltip } from 'antd';
import { ReactComponent as OrderedList } from 'Assets/icons/textEditor/list-ol-solid.svg';
import { ReactComponent as UnorderedList } from 'Assets/icons/textEditor/list-ul-solid.svg';
import { ReactComponent as AlignLeftOutlined } from 'Assets/icons/textEditor/align-left.svg';
import { ReactComponent as AlignCenterOutlined } from 'Assets/icons/textEditor/align-center.svg';
import { ReactComponent as AlignRightOutlined } from 'Assets/icons/textEditor/align-right.svg';
import { ReactComponent as BoldOutlined } from 'Assets/icons/textEditor/bold.svg';
import { ReactComponent as ItalicOutlined } from 'Assets/icons/textEditor/italic.svg';
import { ReactComponent as UnderlineOutlined } from 'Assets/icons/textEditor/underline.svg';
import { FontFamilies } from 'Components/FontSelector/FontFamilies';
import { Button, Toolbar } from './components';
import {
  CustomElement,
  CustomText,
  LIST_TYPES,
  TEXT_ALIGN_TYPES,
} from './types';
import CustomArrowsForSizes from 'Pages/PageGenerationEditor/GenerationPreview/SettingsModal/CustomArrows/CustomArrows';
import ColorSelector from 'Components/ColorSelector/ColorSelector';
import FontSelector from 'Components/FontSelector/FontSelector';
import SizeStyleControl from 'Components/SizeSelector/SizeStyleControls';
import LinksControls from 'Components/LinksControls/LinksControls';

import styles from './TextEditorToolbar.module.scss';

const TextEditorToolbar = ({
  editor,
  selection,
  showListItems = true,
}: {
  editor: Editor | undefined;
  selection: BaseSelection | undefined;
  showListItems?: boolean;
}) => {
  const [currentColor, setCurrentColor] = useState('#000000');
  const [currentFontFamily, setCurrentFontFamily] = useState('Arial');
  const [size, setSize] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [lineHeight, setLineHeight] = useState<string>('');
  const [currentLink, setCurrentLink] = useState<string>('');
  const [activeAlign, setActiveAlign] = useState<string>('left');
  const [activeList, setActiveList] = useState<string>('');
  const [isBold, setIsBold] = useState<boolean>(false);
  const [availableWeights, setAvailableWeights] = useState<number[]>([]);

  const toggleBold = () => {
    const newWeight = isBold
      ? Math.max(...availableWeights.filter((w) => w < 600))
      : Math.min(...availableWeights.filter((w) => w >= 600));

    setWeight(newWeight.toString());
    applyTextStyle('weight', newWeight);
    setIsBold(!isBold);
  };

  useEffect(() => {
    const font = FontFamilies.find((item) => item.value === currentFontFamily);
    const weights = font?.weights || [400];
    setAvailableWeights(font?.weights || [400]);

    if (!weights.includes(Number(weight))) {
      setWeight(weights[0].toString());
      applyTextStyle('weight', weights[0]);
    }
    setIsBold(Number(weight) > 600);
  }, [currentFontFamily, weight]);

  useEffect(() => {
    setCurrentTextAttributes();
  }, [selection, editor]);

  const setCurrentTextAttributes = () => {
    if (!editor || !editor.selection) {
      setDefaults();
      return;
    }

    setCurrentColor(getCurrentAttribute('color', '#000000'));
    setCurrentFontFamily(getCurrentAttribute('font', 'Arial'));
    setSize(getCurrentAttribute('fontSize', '18'));
    setLineHeight(getCurrentAttribute('lineHeight', '1.4'));
    setWeight(getCurrentAttribute('weight', '400'));
    setCurrentLink(getCurrentAttribute('link', ''));
  };

  const setDefaults = () => {
    setCurrentColor('#000000');
    setCurrentFontFamily('Arial');
    setSize('18');
    setLineHeight('1.4');
    setWeight('400');
  };

  if (!editor) return null;

  const getCurrentAttribute = (
    attribute: keyof CustomText,
    defaultValue: string
  ) => {
    const [match] = Editor.nodes<CustomText>(editor, {
      at: editor.selection as any,
      match: (n) => Text.isText(n) && !!(n as CustomText)[attribute],
    });
    return match
      ? (match[0][attribute] as string) || defaultValue
      : defaultValue;
  };

  const toggleBlock = (format: string) => {
    const isActive = isBlockActive(
      format,
      (TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type') as any
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes((n as CustomElement).type),
      split: true,
    });

    let newProperties: Partial<SlateElement>;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = { align: isActive ? undefined : format };
    } else if (isList) {
      newProperties = { type: isActive ? 'paragraph' : 'list-item' };
    } else {
      newProperties = { type: isActive ? 'paragraph' : (format as any) };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block: any = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const toggleMark = (format: string) => {
    const isActive = isMarkActive(format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const applyTextStyle = (type: string, value: string | number) => {
    if (!editor?.selection) return;

    Transforms.setNodes<CustomText>(
      editor,
      { [type]: value },
      { match: (n) => Text.isText(n), split: true }
    );
  };

  const isBlockActive = (format: string, blockType: keyof SlateElement) => {
    if (!selection) return false;

    try {
      const [match] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            (n as CustomElement)[blockType] === format,
        })
      );
      return !!match;
    } catch (error) {
      console.error(
        'Invalid path or node encountered in isBlockActive:',
        error
      );
      return false;
    }
  };

  const isMarkActive = (format: string) => {
    const marks = Editor.marks(editor);

    return marks ? (marks as any)[format] === true : false;
  };

  const BlockButton: React.FC<{ format: string; icon: JSX.Element }> = ({
    format,
    icon,
  }) => {
    useEffect(() => {
      if (TEXT_ALIGN_TYPES.includes(format)) {
        if (isBlockActive(format, 'align' as any)) {
          setActiveAlign(format);
        }
      } else {
        setActiveList(isBlockActive(format, 'type' as any) ? format : '');
      }
    }, [format]);

    return (
      <Button
        active={
          TEXT_ALIGN_TYPES.includes(format)
            ? activeAlign === format
            : activeList === format
        }
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          toggleBlock(format);
          setActiveAlign(format);
        }}
      >
        {icon}
      </Button>
    );
  };

  const MarkButton: React.FC<{ format: string; icon: JSX.Element }> = ({
    format,
    icon,
  }) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
      const active = setIsActive(isMarkActive(format));
      if (selection && typeof active === 'boolean') {
        setIsActive(active);
      }
    }, [format]);

    return (
      <Button
        active={isActive}
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          toggleMark(format);
          setIsActive(!isActive);
        }}
      >
        {icon}
      </Button>
    );
  };

  const onIncrementOrDecrement = (
    type: 'increment' | 'decrement',
    key: string,
    value: string | number,
    max: number,
    min: number,
    step: number,
    callback: (value: string) => void
  ) => {
    const formatNumber = (num: number): string | number => {
      if (num % 1 !== 0) {
        return num.toFixed(1);
      }
      return num;
    };

    if (type === 'increment') {
      if (Number(value) < max) {
        applyTextStyle(key, formatNumber(Number(value) + step));
        callback(formatNumber(Number(value) + step).toString());
      }
    } else {
      if (Number(value) > min) {
        applyTextStyle(key, formatNumber(Number(value) - step));
        callback(formatNumber(Number(value) - step).toString());
      }
    }
  };

  return (
    <Toolbar>
      <Tooltip title="Color">
        <div>
          <ColorSelector
            onChange={(color) => {
              applyTextStyle('color', color);
              setCurrentColor(color);
            }}
            color={currentColor}
          />
        </div>
      </Tooltip>
      <Tooltip title="Font Family">
        <div>
          <FontSelector
            select={currentFontFamily}
            onChange={(value) => {
              applyTextStyle('font', value);
              setCurrentFontFamily(value);
            }}
            onlyWebSafe
          />
        </div>
      </Tooltip>
      <Tooltip title="Font Size">
        <div>
          <SizeStyleControl
            step={1}
            max={200}
            min={1}
            value={size}
            onChange={(value) => {
              applyTextStyle('fontSize', Number(value));
              setSize(value);
            }}
            customArrows={
              <CustomArrowsForSizes
                className={styles.arrows}
                onIncrement={() =>
                  onIncrementOrDecrement(
                    'increment',
                    'fontSize',
                    size,
                    200,
                    1,
                    1,
                    setSize
                  )
                }
                onDecrement={() =>
                  onIncrementOrDecrement(
                    'decrement',
                    'fontSize',
                    size,
                    200,
                    1,
                    1,
                    setSize
                  )
                }
              />
            }
          />
        </div>
      </Tooltip>
      <Tooltip title="Line Height">
        <div>
          <SizeStyleControl
            step={0.1}
            max={10}
            min={0}
            value={lineHeight}
            onChange={(value) => {
              applyTextStyle('lineHeight', Number(value));
              setLineHeight(value);
            }}
            customArrows={
              <CustomArrowsForSizes
                className={styles.arrows}
                onIncrement={() =>
                  onIncrementOrDecrement(
                    'increment',
                    'lineHeight',
                    lineHeight,
                    10,
                    0,
                    0.1,
                    setLineHeight
                  )
                }
                onDecrement={() =>
                  onIncrementOrDecrement(
                    'decrement',
                    'lineHeight',
                    lineHeight,
                    10,
                    0,
                    0.1,
                    setLineHeight
                  )
                }
              />
            }
          />
        </div>
      </Tooltip>
      <Tooltip title="Font Weight" placement="bottom">
        <div>
          <SizeStyleControl
            step={100}
            max={Math.max(...availableWeights)}
            min={Math.min(...availableWeights)}
            value={weight}
            onChange={(value) => {
              const newWeight = Number(value);
              if (availableWeights.includes(newWeight)) {
                setWeight(value);
                applyTextStyle('weight', newWeight);
                setIsBold(newWeight > 600);
              }
            }}
            customArrows={
              <CustomArrowsForSizes
                className={styles.arrows}
                onIncrement={() => {
                  const currentIndex = availableWeights.indexOf(Number(weight));
                  if (
                    currentIndex >= 0 &&
                    currentIndex < availableWeights.length - 1
                  ) {
                    const newWeight = availableWeights[currentIndex + 1];
                    setWeight(newWeight.toString());
                    applyTextStyle('weight', newWeight);
                    setIsBold(newWeight > 600);
                  }
                }}
                onDecrement={() => {
                  const currentIndex = availableWeights.indexOf(Number(weight));
                  if (currentIndex > 0) {
                    const newWeight = availableWeights[currentIndex - 1];
                    setWeight(newWeight.toString());
                    applyTextStyle('weight', newWeight);
                    setIsBold(newWeight > 600);
                  }
                }}
              />
            }
          />
        </div>
      </Tooltip>
      <Tooltip title="Bold">
        <div>
          <Button
            active={isBold}
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              toggleBold();
            }}
          >
            <BoldOutlined />
          </Button>
        </div>
      </Tooltip>
      <Tooltip title="Italic">
        <div>
          <MarkButton format="italic" icon={<ItalicOutlined />} />
        </div>
      </Tooltip>
      <Tooltip title="Underline">
        <div>
          <MarkButton format="underline" icon={<UnderlineOutlined />} />
        </div>
      </Tooltip>
      {showListItems && (
        <>
          <BlockButton format="number-list" icon={<OrderedList />} />
          <BlockButton
            format="bulleted-list"
            icon={<UnorderedList fill="black" />}
          />
        </>
      )}
      <Tooltip title="Left">
        <div>
          <BlockButton format="left" icon={<AlignLeftOutlined />} />
        </div>
      </Tooltip>
      <Tooltip title="Center">
        <div>
          <BlockButton format="center" icon={<AlignCenterOutlined />} />
        </div>
      </Tooltip>
      <Tooltip title="Right">
        <div>
          <BlockButton format="right" icon={<AlignRightOutlined />} />
        </div>
      </Tooltip>
      <Tooltip title="Url">
        <div>
          <LinksControls
            onChange={(value) => {
              applyTextStyle('link', value);
              setCurrentLink(value);
            }}
            value={currentLink}
          />
        </div>
      </Tooltip>
    </Toolbar>
  );
};

export default TextEditorToolbar;
