import { useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import {
  Draggable,
  Droppable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';
import {
  queueUpdatePage,
  queueSetPagesOrder,
  queueArchiveFunnel,
} from 'store/projects/projectsQueueActions';
import { IFunnel, IGeneralProject, IOrder, IDomain } from 'types';
import { FunnelTotalStats } from 'services/types';
import { RootState } from 'store/rootReducer';
import { getActiveProjectData } from 'store/projects/projectsActions';
import RenderPage from '../RenderPage/RenderPage';
import s from './PageProjectsLanding.module.scss';


type DispatchType = ThunkDispatch<RootState, void, Action>;

interface IProps {
  project: IGeneralProject;
  connectedDomain: IDomain | undefined;
  getActiveProjectData: (id: string, silent: boolean) => void;
  removePage: (pageId: string) => void;
  updatePage: (pageId: string, name: string, enabled: boolean) => void;
  onDuplicate: (pageId: string, funnelId?: string) => void;
  onOpenPageSettings: (page: IFunnel) => void;
  onOpenPageModal?: (pageId: string) => void;
  setPagesOrder: (projectId: string, order: IOrder[]) => void;
  domain?: string;
  isAgency: boolean;
  pagesStatistics?: Record<string, FunnelTotalStats>;
  duplicatePageToAnotherAccount: (pageId: string) => void;
}

const PageProjectsLanding = ({
  project,
  removePage,
  connectedDomain,
  updatePage,
  onDuplicate,
  onOpenPageSettings,
  setPagesOrder,
  isAgency,
  pagesStatistics,
  duplicatePageToAnotherAccount,
  getActiveProjectData,
}: IProps) => {
  const [sortedPages, setSortedPages] = useState<IFunnel[]>([]);
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(
    null
  );
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTopStartDrag, setScrollTopStartDrag] = useState(0);
  const history = useHistory();

  useEffect(() => {
    // For handling page resize
    const resetSwipe = (e: UIEvent) => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1280) setSwipeDirection('left');
    };

    window.addEventListener('resize', resetSwipe);

    return () => {
      window.removeEventListener('resize', resetSwipe);
    };
  }, []);

  useEffect(() => {
    const pages = getSortedPages(project);
    setSortedPages(pages);
  }, [project]);

  function handleScroll(e: any) {
    const element = e.target;

    const currentScrollLeft = element.scrollLeft;
    if (currentScrollLeft > scrollLeft) {
      setSwipeDirection('left');
    } else if (currentScrollLeft < 5) {
      setSwipeDirection('right');
    }
    setScrollLeft(currentScrollLeft);
  }

  const handleDuplicate = (id: string, funnelId?: string) => {
    onDuplicate(id, funnelId);
  };

  const handleDelete = (id: string) => {
    removePage(id);
  };

  const handleOpenStatistics = (id: string) => {
    history.push(`/console/projects/${project._id}/page/${id}`);
  };

  const getSortedPages = (project: IGeneralProject) => {
    let pages = project.pages.filter((item) => item.archived === false);
    let sortedPages: IFunnel[] = [];
    if (project.order && project.order.length !== 0) {
      project.order.forEach((key) => {
        pages.forEach((page) => {
          if (page._id === key.id) {
            sortedPages.push(page);
          }
        });
      });
      pages = sortedPages;
    }

    return pages;
  };

  const handleOpenSettings = (page: IFunnel) => {
    onOpenPageSettings(page);
  };

  const createOrder = (pages: IFunnel[]) => {
    let newOrder: IOrder[] = [];

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      if (page.name) {
        let item: IOrder = {
          id: page._id.toString(),
          enabled: pages[i].enabled,
        };
        if (i !== 0) {
          item.previous = pages[i - 1]._id.toString();
        }
        if (i < pages.length - 1) {
          item.next = pages[i + 1]._id.toString();
        }

        newOrder.push(item);
      }
    }
    return newOrder;
  };

  const handlePagesSorting = (res: DropResult) => {
    if (!res.destination) return;
    const source = res.source.index;
    const dest = res.destination.index;
    if (dest === source) {
      return;
    }

    if (!project.order || project.order.length == 0) {
      project.order = createOrder(project.pages);
    }
    const [removed] = project.order.splice(source, 1);
    project.order.splice(dest, 0, removed);

    const pages = getSortedPages(project);
    setSortedPages(pages);
    setPagesOrder(project._id, project.order);
  };

  const getOrderItem = (items: IOrder[], id: string) => {
    let foundItem: undefined | IOrder;
    items.forEach((item) => {
      if (id === item.parent) {
        foundItem = item;
      }
    });
    return foundItem;
  };

  const chainOrder = (
    allItems: IOrder[],
    id: string,
    items: IOrder[] = []
  ): IOrder[] => {
    const item = getOrderItem(allItems, id);
    if (item) {
      return chainOrder(allItems, item.id, [...items, item]);
    }
    return items;
  };

  const savePageName = (name: string, pageId?: string, enabled?: boolean) => {
    if (pageId && enabled) {
      updatePage(pageId, name, enabled);
    }
  };

  const getItemStyle = (snapshot: any, draggableStyle: any) => {
    const transform = draggableStyle.transform;
    const differenceBetweenScrollAndElement = scrollTopStartDrag;
    const regexX = /translate\((-?\d+\.?\d*)px,.*\)/;
    let transformX = 0;
    if (transform) {
      const match = transform.match(regexX);
      if (match && match[1]) {
        transformX = parseFloat(match[1]);
      }
    }
    const regexY = /translate\(-?\d+\.?\d*px, (-?\d+\.?\d*)px\)/;
    let transformY = 0;
    if (transform) {
      const match = transform.match(regexY);
      if (match && match[1]) {
        transformY = parseFloat(match[1]);
      }
    }
    if (snapshot.isDragging) {
      draggableStyle.transform = `translate(${transformX}px, ${transformY - differenceBetweenScrollAndElement
        }px)`;
    }

    return {
      cursor: snapshot.isDragging ? 'all-scroll' : 'pointer',
      ...draggableStyle,
      top: '0px !important',
    };
  };

  const handleFunnelReload = () => {
    getActiveProjectData(project._id, true);
  };


  return (
    <div onScroll={handleScroll} className={s.wrapper}>
      <DragDropContext
        onDragEnd={(res) => handlePagesSorting(res)}
        onDragStart={(res) =>
          setScrollTopStartDrag(
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop
          )
        }
      >
        <div className={s.droppable}>
          <Droppable droppableId="pages">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortedPages.map((page, index) => (
                  <div
                    style={{
                      pointerEvents:
                        page.status === 'GENERATING' ? 'none' : 'auto',
                    }}
                    key={page._id + index}
                  >
                    <Draggable
                      key={page._id}
                      draggableId={page._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot,
                            provided.draggableProps.style
                          )}
                        >
                          <RenderPage
                            page={page}
                            project={project}
                            connectedDomain={connectedDomain}
                            handleOpenSettings={handleOpenSettings}
                            handleDuplicate={handleDuplicate}
                            savePageName={savePageName}
                            handleDelete={handleDelete}
                            handleOpenStatistics={handleOpenStatistics}
                            swipeDirection={swipeDirection}
                            statistic={
                              pagesStatistics ? pagesStatistics[page._id] : null
                            }
                            isAgency={isAgency}
                            duplicatePageToAnotherAccount={() =>
                              duplicatePageToAnotherAccount(page._id)
                            }
                            onReloadPage={() => handleFunnelReload()}
                          />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
  getActiveProjectData: (id: string, silent: boolean) => dispatch(getActiveProjectData(id,silent)),
  removePage: (id: string) => dispatch(queueArchiveFunnel(id)),
  setPagesOrder: (projectId: string, order: IOrder[]) =>
    dispatch(queueSetPagesOrder(projectId, order)),
  updatePage: (funnelId: string, name: string, enabled: boolean) =>
    dispatch(queueUpdatePage({ name, enabled }, funnelId)),
  updateFunnel: (fields: any, funnelId: string) =>
    dispatch(queueUpdatePage(fields, funnelId)),
});

export default connect(null, mapDispatchToProps)(PageProjectsLanding);
