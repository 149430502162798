import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as MoveIcon } from 'Assets/icons/move.svg';
import { ReactComponent as RenderPageSales } from 'Assets/icons/renderPageSales.svg';
import { ReactComponent as RenderPageLeadsSvg } from 'Assets/icons/renderPageLeads.svg';
import { ReactComponent as RenderPageVisitorsSvg } from 'Assets/icons/renderPageVisitors.svg';
import { IFunnel, IGeneralProject, IDomain } from 'types';
import { handleRedirectToEditor } from 'store/projects/projectsActions';
import { DATA_URL, ENVIRONMENT } from 'Constants';
import { FunnelTotalStats } from 'services/types';
import { createSocket } from 'utils/Utils';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import ContentEditableDiv from 'Components/Common/ContentEditableDiv/ContentEditableDiv';
import funnelPreview from 'Assets/images/funnelPreview.svg';
import pageLink from 'Assets/icons/contextMenu/pageLink.svg';
import PageSubMenu from './PageSubMenu';
import PageImagePlaceholder from '../../Placeholders/PageImagePlaceholder/PageImagePlaceholder';
import CopyToAnotherFunnelPopup from './CopyToAnotherFunnelPopup';

import s from './RenderPage.module.scss';

interface IProps {
  page: IFunnel;
  project: IGeneralProject;
  connectedDomain: IDomain | undefined;
  domain?: string;
  savePageName: (name: string, pageId?: string, enabled?: boolean) => void;
  handleOpenSettings: (page: IFunnel) => void;
  handleDuplicate: (id: string, funnelId?: string) => void;
  handleDelete: (id: string) => void;
  handleOpenStatistics: (id: string) => void;
  swipeDirection?: 'left' | 'right' | null;
  statistic: FunnelTotalStats | null;
  isAgency: boolean;
  duplicatePageToAnotherAccount: () => void;
  onReloadPage?: () => void;
}

const RenderPage = (props: IProps) => {
  const [pageName, setPageName] = useState('');
  const [loading, setLoading] = useState(false);
  const [archivePagePopupOpen, setArchivePagePopupOpen] = useState(false);

  const [
    selectFunnelForDuplicateOpen,
    setSelectFunnelForDuplicateOpen,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (pageName === '') {
      setPageName(props.page.name);
    }

    if (props.page.status === 'GENERATING') {
      handlePageWaiting(props.page._id);
    }
  }, []);

  const handlePageWaiting = async (pageId: string) => {
    setLoading(true);
    try {
      await waitPageGenerating(pageId);
      props.page.status = 'COMPLETE';
      if (props.onReloadPage) {
        props.onReloadPage();
      }
    } catch (err) {
      console.error('Error page generation', err);
      if (props.onReloadPage) {
        props.onReloadPage();
      }
    }
    setLoading(false);
  };

  const waitPageGenerating = async (pageId: string) => {
    return new Promise((resolve, reject) => {
      const socket = createSocket();
      const MAX_TRIES = 10;
      const MAX_TIMEOUT = 1000 * 60 * 5; // 5 minutes

      let tries = 0;
      const interval = setInterval(() => {
        tries++;
        if (tries > MAX_TRIES) {
          clearInterval(interval);
        }
        socket.emit('ai-get-full-page-info', { pageId });
      }, 2000);

      const timeOut = setTimeout(() => {
        clearTimeout(timeOut);
        clearInterval(interval);
        socket.disconnect();
        reject('maximum waiting time reached');
      }, MAX_TIMEOUT);

      socket.on(
        'ai-full-page-info-response',
        (res: { progress: number; status: string; pageId: string }) => {
          if (res.status !== 'GENERATING') {
            setTimeout(() => {
              clearInterval(interval);
              socket.disconnect();
              resolve('COMPLETE');
            }, 10000);
          }
        }
      );
    });
  };

  const onDuplicate = (pageId: string) => {
    props.handleDuplicate(pageId);
  };

  const onDuplicateToAnotherFunnel = (e: any, funnelId: string) => {
    props.handleDuplicate(props.page._id, funnelId);
  };

  const generatePublicPageUrl = () => {
    let rootDomain = 'autofunnel.ai';
    if (ENVIRONMENT === 'DEV') {
      rootDomain = 'dev.autofunnel.ai';
    }
    if (props.connectedDomain) {
      return `https://${props.connectedDomain.name}/${props.page._id}/optin`;
    }

    if (props.project.customSubDomain) {
      return `https://${props.project.customSubDomain}.${rootDomain}/${props.page._id}/optin`;
    }

    return `https://live.${rootDomain}/${props.page._id}/optin`;
  };

  const handleCopyPageUrl = () => {
    navigator.clipboard.writeText(generatePublicPageUrl());
  };

  const handleCopyToAnotherFunnel = () => {
    if (props.isAgency) {
      props.duplicatePageToAnotherAccount();
    } else {
      setSelectFunnelForDuplicateOpen(true);
    }
  };

  const getPageImage = (page: IFunnel) => {
    let image = null;
    if (page.pages && page.pages.length > 0) {
      image = page.pages[0].image;
    }
    return image ? (
      <img
        className={s.funnelPreviewImage}
        src={`${DATA_URL}/${image}`}
        alt="preview"
      />
    ) : (
      <img className={s.funnelPreviewImage} alt="preview" src={funnelPreview} />
    );
  };

  const getFunnelPreviewClass = useMemo(() => {
    if (!props.swipeDirection) return s.funnelPreview;
    if (props.swipeDirection === 'left') {
      return s.funnelPreview;
    }
    if (props.swipeDirection === 'right') {
      return s.funnelPreviewSmall;
    }
  }, [props.swipeDirection]);

  return (
    <div className={s.outerContainer}>
      <div
        className={s.container}
        key={props.page._id}
        onClick={() => {
          if (loading) return;
          handleRedirectToEditor(props.page._id);
        }}
      >
        <div className={getFunnelPreviewClass}>
          {loading ? <PageImagePlaceholder /> : getPageImage(props.page)}
          <div className={s.funnelImageHoverTransparentBgc} />
        </div>
        <div className={s.infoWrapper}>
          <MoveIcon fill="#d0d0d0" className={s.moveSvg} />
          <div className={s.funnelName}>
            <ContentEditableDiv
              name={pageName}
              fontSize={20}
              lineHeight={26}
              fontWeight={500}
              height={30}
              page={props.page}
              onChange={setPageName}
              onEdit={props.savePageName}
            />

            {loading ? (
              <div className={s.generatingProgressText}>
                Generating in progress...
              </div>
            ) : (
              <div
                className={s.subheader}
                onClick={(e) => {
                  e.stopPropagation();

                  window.open(generatePublicPageUrl());
                }}
              >
                <img
                  src={pageLink}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyPageUrl();
                  }}
                  alt="link"
                />
                {generatePublicPageUrl()}
              </div>
            )}
          </div>
          <div className={s.statistic}>
            <div>
              <RenderPageVisitorsSvg />
              {props.statistic ? props.statistic.total_visitors : 0}
            </div>
            <div>
              <RenderPageLeadsSvg />
              {props.statistic ? props.statistic.total_leads : 0}
            </div>
            <div>
              <RenderPageSales />
              {props.statistic ? props.statistic.total_sales : 0}
            </div>
          </div>
          <div className={s.funnelSettings}>
            <PageSubMenu
              onSettings={() => props.handleOpenSettings(props.page)}
              onDuplicate={() => onDuplicate(props.page._id)}
              onDuplicateToOtherFunnel={handleCopyToAnotherFunnel}
              onCopyPageURL={handleCopyPageUrl}
              onArchive={() => setArchivePagePopupOpen(true)}
              onStatistics={() => props.handleOpenStatistics(props.page._id)}
            />
          </div>
        </div>
      </div>
      <CopyToAnotherFunnelPopup
        open={selectFunnelForDuplicateOpen}
        onClose={() => setSelectFunnelForDuplicateOpen(false)}
        onDuplicateToAnotherFunnel={onDuplicateToAnotherFunnel}
      />
      <ConfirmArchivationPopup
        open={archivePagePopupOpen}
        description="Once you delete your page, all the features and data from it will be gone. Are you sure you want to continue?"
        onDonePressed={() => {
          props.handleDelete(props.page._id);
        }}
        onClose={() => setArchivePagePopupOpen(false)}
      />
    </div>
  );
};

export default RenderPage;
