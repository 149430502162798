import clsx from 'clsx';
import { ReactComponent as Undo } from 'Assets/icons/undo.svg';
import { ReactComponent as Redo } from 'Assets/icons/redo.svg';

import styles from './UndoRedoButtons.module.scss';

interface IProps {
  undo: () => void;
  redo: () => void;
  disabledUndo?: boolean;
  disabledRedo?: boolean;
}

const UndoRedoButtons = ({
  undo,
  redo,
  disabledRedo,
  disabledUndo,
}: IProps) => {
  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.button, styles.undo, {
          [styles.disabled]: disabledUndo,
        })}
        onClick={() => !disabledUndo && undo()}
      >
        <Undo />
      </div>
      <div
        className={clsx(styles.button, styles.redo, {
          [styles.disabled]: disabledRedo,
        })}
        onClick={() => !disabledRedo && redo()}
      >
        <Redo />
      </div>
    </div>
  );
};

export default UndoRedoButtons;
