export const GenerateListDefaultData = {
  name: 'Generate List',
  type: 'GenerateList',
  prompt: 'Prompt',
  width: 300,
  height: 200,
  x: 0,
  y: 0,
  id: '',
  variable: 'list',
  operation: null,
  connections: [],
};

export const GenerateTextDefaultData = {
  name: 'Generate Text',
  type: 'GenerateText',
  prompt: '',
  width: 300,
  height: 200,
  x: 0,
  y: 0,
  id: '',
  variable: 'text',
  operation: null,
  connections: [],
};

export const GenerateImageDefaultData = {
  name: 'Generate Image',
  type: 'GenerateImage',
  prompt: '',
  width: 300,
  height: 200,
  x: 0,
  y: 0,
  id: '',
  variable: 'image',
  operation: null,
  connections: [],
};

export const UserInputDefaultData = {
  name: 'User Input',
  type: 'UserInput',
  forms: [
    {
      name: 'Form Title',
      fields: [{ label: 'Field Name', id: 'input', type: 'Input' }],
    },
  ],
  width: 300,
  height: 400,
  x: window.innerWidth / 2 - 150,
  y: 20,
  id: '',
  variable: null,
  operation: null,
  connections: [],
};
