import SkeletonItem from '../SkeletonItem/SkeletonItem';

import styles from './BooksSkeleton.module.scss';

const BooksSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.line}>
          <SkeletonItem className={styles.pageName} />
          <SkeletonItem className={styles.addButton} />
        </div>
        <div className={styles.line}>
          <SkeletonItem className={styles.addButton} />
          <SkeletonItem className={styles.sort} />
        </div>
      </div>
      <div className={styles.mobileHeader}>
        <SkeletonItem className={styles.mobileHeaderSkeleton} />
      </div>
      <div className={styles.body}>
        {Array(16)
          .fill(null)
          .map((_, index) => {
            return <SkeletonItem className={styles.item} key={index} />;
          })}
      </div>
    </div>
  );
};

export default BooksSkeleton;
