import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IApps } from 'Components/AutoSaas/constants';
import { IAppsNames, IAutoSassDesign } from './autosaasReducer';
import { RootState } from '../rootReducer';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import { getDomain } from 'utils/helpers';

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const SET_AUTOSAAS_LOGO = 'SET_AUTOSAAS_LOGO';
export const APPS_NAMES_FETCHED = 'APPS_NAMES_FETCHED';
export const AUTOSASS_DESIGN_FETCHED = 'AUTOSASS_DESIGN_FETCHED';
export const GET_AUTOSASS_APPS = 'GET_AUTOSASS_APPS';
export const DELETE_AUTOSASS_APP = 'DELETE_AUTOSASS_APP';
export const UPDATE_AUTOSASS_APP = 'UPDATE_AUTOSASS_APP';

export const setAutoSaasLogoUrl = (url: string) => async (
  dispatch: DispatchType
) => {
  dispatch({
    type: SET_AUTOSAAS_LOGO,
    payload: url,
  });
};

export const appsFetched = (payload: IAppsNames[]) => ({
  type: APPS_NAMES_FETCHED,
  payload,
});

export const fetchedAutoSassDesign = (payload: IAutoSassDesign) => ({
  type: AUTOSASS_DESIGN_FETCHED,
  payload,
});

export const fetchAutoSassDesign = () => async (
  dispatch: DispatchType,
  getState: () => RootState
) => {
  try {
    const hasAutoSaasAccess = getState().user?.agencyDetails?.scopes?.includes(
      'SAAS_OWNER'
    );
    const { domain: initialDomain } = getDomain();

    let response;
    if (hasAutoSaasAccess) {
      response = await graphQlCall({
        queryTemplateObject: queries.GET_MY_SASS_DESIGN,
        headerType: 'USER-AUTH',
      });
    } else {
      response = await graphQlCall({
        queryTemplateObject: queries.GET_SASS_DESIGN,
        values: {
          domain:
            initialDomain === 'localhost:3000'
              ? 'app.dev.autofunnel.ai'
              : initialDomain,
        },
      });
    }

    const { apps } = response;
    dispatch(fetchedAutoSassDesign(response));

    dispatch(
      appsFetched(
        apps?.map((app: IApps) => ({
          name: app?.name,
          key: app?.key,
        })) as IAppsNames[]
      )
    );
  } catch (error) {
    console.error(error);
  }
};

export const getAutoSassApps = () => async (
  dispatch: DispatchType,
  getState: () => RootState
) => {
  const response = await graphQlCall({
    queryTemplateObject: queries.GET_USER_AUTO_APPS,
    headerType: 'USER-AUTH',
  });

  dispatch({
    type: GET_AUTOSASS_APPS,
    payload: response,
  });
};

export const deleteAutoSassApp = (id: string) => async (
  dispatch: DispatchType
) => {
  await graphQlCall({
    queryTemplateObject: queries.DELETE_AUTO_APP,
    values: {
      id,
    },
    headerType: 'USER-AUTH',
  });

  dispatch({
    type: DELETE_AUTOSASS_APP,
    payload: id,
  });
};

export const updateAutoSassApp = (data: {
  id: string;
  name?: string;
  description?: string;
  iconUrl?: string;
  templateId?: string;
}) => async (dispatch: DispatchType) => {
  const response = await graphQlCall({
    queryTemplateObject: queries.UPDATE_AUTO_APP,
    values: data,
    headerType: 'USER-AUTH',
  });

  dispatch({
    type: UPDATE_AUTOSASS_APP,
    payload: response,
  });
};
