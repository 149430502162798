import { ChangeEvent, useMemo, useState } from 'react';
import { ReactComponent as FilterIcon } from 'Assets/icons/filter.svg';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import { Tag } from '../Helper/types';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import CustomDropdown from 'Components/CustomDropdown/CustomDropdown';
import Button from 'UILib/Button/Button';
import Input from 'UILib/Input/Input';
import clsx from 'clsx';

import s from 'Pages/PageContacts/SmartList/SmartListPopup.module.scss';

interface Props {
  smart: boolean;
  setSmart: (value: boolean) => void;
  onAddNewSmartList: (value: any) => void;
  uniqueTags: Tag[];
  fetchSmartLists: () => void;
  className?: string;
}

const SmartList = ({
  fetchSmartLists,
  setSmart,
  onAddNewSmartList,
  smart,
  uniqueTags,
  className,
}: Props) => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [includeTagsArray, setIncludeTagsArray] = useState<string[]>([]);
  const [excludeTagsArray, setExcludeTagsArray] = useState<string[]>([]);
  const [includeAnyTagsArray, setIncludeAnyArray] = useState<string[]>([]);
  const [excludeAllTagsArray, setExcludeAllArray] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [includeTagsSearch, setIncludeTagsSearch] = useState<string>('');
  const [excludeTagsSearch, setExcludeTagsSearch] = useState<string>('');

  const filteredIncludedTags = useMemo(() => {
    return uniqueTags
      .filter((e) => e.name.includes(includeTagsSearch))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [includeTagsSearch, uniqueTags]);

  const filteredExcludedTags = useMemo(() => {
    return uniqueTags
      .filter((e) => e.name.includes(excludeTagsSearch))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [excludeTagsSearch, uniqueTags]);

  const handleSmartClose = () => {
    if (smart) setSmart(false);
    setIncludeTagsArray([]);
    setExcludeAllArray([]);
    setExcludeTagsArray([]);
    setIncludeAnyArray([]);
    setName('');
    setBtnDisabled(true);
  };

  const handleSmartAdd = async () => {
    if (!btnDisabled) {
      setSmart(false);
      const newListName = name.trim();
      const exact: string[] = [];
      const includeAll: string[] = [];
      const includeAny: string[] = includeAnyTagsArray;
      const excludeAll: string[] = excludeAllTagsArray;
      const maxContactAge = undefined;
      try {
        const response = await graphQlCall({
          queryTemplateObject: QUERIES.CREATE_SMART_LIST,
          values: {
            name: newListName,
            exact,
            includeAll,
            includeAny,
            excludeAll,
            maxContactAge,
          },
          headerType: 'USER-AUTH',
        });
        if (response) {
          onAddNewSmartList(response);
          handleSmartClose();
        } else {
          throw new Error('Failed to create smart list');
        }
      } catch (error) {
        fetchSmartLists();
        console.error('Error creating smart list:', error);
      }
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
    updateSecondBtnDisabled(value, includeTagsArray);
  };

  const updateSecondBtnDisabled = (nameValue: string, tagsArray: string[]) => {
    if (nameValue.trim() === '' || tagsArray.length === 0) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  };

  const handleTagClick = (tag: Tag, type: 'exclude' | 'include') => {
    const tagName = tag.name;
    const tagId = tag._id;

    if (type === 'include') {
      if (!includeTagsArray.includes(tagName)) {
        const updatedTags = [...includeTagsArray, tagName];
        setIncludeTagsArray(updatedTags);
        updateSecondBtnDisabled(name, updatedTags);

        const updatedExactTags = [...includeAnyTagsArray, tagId];

        setIncludeAnyArray(updatedExactTags);
      }
    } else {
      if (!excludeTagsArray.includes(tagName)) {
        const updatedTags = [...excludeTagsArray, tagName];
        setExcludeTagsArray(updatedTags);

        const updatedExactTags = [...excludeAllTagsArray, tagId];

        setExcludeAllArray(updatedExactTags);
      }
    }
  };

  const handleTagRemove = (
    tagToRemove: string,
    type: 'exclude' | 'include'
  ) => {
    if (type === 'include') {
      const updatedTags = includeTagsArray.filter((tag) => tag !== tagToRemove);
      setIncludeTagsArray(updatedTags);
      updateSecondBtnDisabled(name, updatedTags);
    } else {
      const updatedTags = excludeTagsArray.filter((tag) => tag !== tagToRemove);
      setExcludeTagsArray(updatedTags);
    }
  };

  if (!smart) return null;

  return (
    <div className={s.container}>
      <div className={s.overlay} onClick={handleSmartClose} />
      <div className={s.coverContainer} onClick={handleSmartClose}>
        <div
          className={clsx(s.smartCover, className)}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <div>
              <div>
                <FilterIcon />
              </div>
              <div className={s.filterText}>
                <div className={s.filterTopText}>Create Smart List</div>
                <div className={s.filterBottomtext}>based on your tags</div>
              </div>
              <CrossIcon className={s.close} onClick={handleSmartClose} />
            </div>
            <div className={s.hr}></div>
            <p>
              Name<span>*</span>
            </p>
            <input
              placeholder={'Give a Name'}
              value={name}
              onChange={handleNameChange}
              className={s.filterInput}
            />
          </div>
          <div>
            <p>
              Contain Tags<span>*</span>
            </p>
            <CustomDropdown
              label="Select Tags"
              size="medium"
              header={
                <div className={s.searchContainer}>
                  <Input
                    placeholder="Search"
                    border="stroke"
                    className={s.search}
                    onChange={(e) => setIncludeTagsSearch(e.target.value)}
                    value={includeTagsSearch}
                  />
                </div>
              }
              options={filteredIncludedTags.map((tag) => ({
                label: tag.name,
                value: tag._id,
              }))}
              onChange={(selectedTagId) => {
                const selectedTag = uniqueTags.find(
                  (tag) => tag._id === selectedTagId
                );
                if (selectedTag) {
                  handleTagClick(selectedTag, 'include');
                }
              }}
            />
            <div className={s.addedTags}>
              {includeTagsArray.map((tag, index) => (
                <div key={index} className={s.tag}>
                  {tag}
                  <CrossIcon
                    className={s.crossBtn}
                    onClick={() => handleTagRemove(tag, 'include')}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={s.excludeTagsContainer}>
            <p>Exclude Tags</p>
            <CustomDropdown
              label="Select Tags"
              size="medium"
              options={filteredExcludedTags.map((tag) => ({
                label: tag.name,
                value: tag._id,
              }))}
              header={
                <div className={s.searchContainer}>
                  <Input
                    placeholder="Search"
                    border="stroke"
                    className={s.search}
                    onChange={(e) => setExcludeTagsSearch(e.target.value)}
                    value={excludeTagsSearch}
                  />
                </div>
              }
              onChange={(selectedTagId) => {
                const selectedTag = uniqueTags.find(
                  (tag) => tag._id === selectedTagId
                );
                if (selectedTag) {
                  handleTagClick(selectedTag, 'exclude');
                }
              }}
            />
            <div className={s.addedTags}>
              {excludeTagsArray.map((tag, index) => (
                <div key={index} className={s.tag}>
                  {tag}
                  <CrossIcon
                    className={s.crossBtn}
                    onClick={() => handleTagRemove(tag, 'exclude')}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={s.filterBtns}>
            <Button height={40} appearance="stroke" onClick={handleSmartClose}>
              Cancel
            </Button>
            <Button
              height={40}
              appearance="highlighted"
              onClick={handleSmartAdd}
              disabled={btnDisabled}
            >
              Create List
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartList;
