import { useEffect, useState } from 'react';
import { ReactComponent as Plus } from 'Assets/icons/plusAdd.svg';
import SearchInput from 'UILib/SearchInput/SearchInput';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Button from 'UILib/Button/Button';

import styles from './DashBoardHeader.module.scss';

interface DashboardHeaderProps {
  sortOptions: {
    value: string;
    label: string;
  }[];
  handleSort: (value: string) => void;
  handleSearch: (value: string) => void;
  total: string;
  title: string;
  buttonText: string;
  handleAddNewItem: () => void;
  sortBy?: string;
  additionalSort?: JSX.Element;
}

const DashboardHeader = (props: DashboardHeaderProps) => {
  const [sortItem, setSortItem] = useState<string>(props.sortBy || '');
  const [searchedText, setSearchedText] = useState<string>('');

  const handleSort = (value: string) => {
    props.handleSort(value);
    setSortItem(value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      props.handleSearch(searchedText);
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [searchedText]);

  const handleSearch = (text: string) => {
    setSearchedText(text);
  };

  return (
    <div className={styles.container}>
      <div className={styles.line}>
        <div className={styles.title}>{props.title}</div>
        <Button
          appearance="highlighted"
          width={220}
          height={40}
          postfixIcon={<Plus />}
          onClick={props.handleAddNewItem}
          className={styles.confirmButton}
        >
          {props.buttonText}
        </Button>
      </div>
      <div className={styles.line}>
        <div className={styles.searchBar}>
          <SearchInput
            onChange={handleSearch}
            border="stroke"
            className={styles.search}
          />
          <div className={styles.total}>{props.total}</div>
        </div>
        <div className={styles.sortContent}>
          {props.additionalSort}
          <div className={styles.sortBy}>
            <div className={styles.label}>Sort by:</div>
            <Dropdown
              className={styles.sort}
              label={
                props.sortOptions.find((e) => e.value === sortItem)?.label ?? ''
              }
              options={props.sortOptions}
              onChange={handleSort}
              hasErrors={false}
              optionsClassName={styles.optionsClassName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
