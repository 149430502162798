import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import {
  updateActiveTemplate,
  updateBookleTemplateBlocks,
  updateBookleTemplateHoveredBlock,
} from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { MenuItems } from '../Sidebar/Sidebar';
import { BookleTemplateBlock } from 'types';
import { moveItem } from '../utils';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import Toolbar from '../toolbar/toolbar';
import DropPlace from '../DropPlace/DropPlace';
import SectionBlocks from '../SectionBlock/SectionBlocks';
import ImageBlock from '../ImageBlock/ImageBlock';
import TextBlock from '../TextBlock/TextBlock';
import DividerBlock from '../DividerBlock/DividerBlock';
import ButtonBlock from '../ButtonBlock/ButtonBlock';

import styles from './DraggableContent.module.scss';

const DraggableItem = ({ item }: { item: BookleTemplateBlock }) => {
  switch (item?.type) {
    case MenuItems.ONE_SECTION:
    case MenuItems.TWO_SECTION:
    case MenuItems.THREE_SECTION:
    case MenuItems.FOUR_SECTION:
      return <SectionBlocks item={item} />;
    case MenuItems.TEXT_BLOCK:
    case MenuItems.HEADING_BLOCK:
      return <TextBlock item={item} />;
    case MenuItems.IMAGE_BLOCK:
      return <ImageBlock item={item} />;
    case MenuItems.DIVIDER_BLOCK:
    case MenuItems.SPACER_BLOCK:
      return <DividerBlock item={item} />;
    case MenuItems.BUTTON_BLOCK:
      return <ButtonBlock item={item} />;
    default:
      return <></>;
  }
};

interface IProps {
  item: BookleTemplateBlock;
  parentId?: string;
  updateHoveredItem: (payload: BookleTemplateBlock | undefined) => void;
  templateBlocks: BookleTemplateBlock[];
  hoveredItem: BookleTemplateBlock | undefined;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  updateActiveBlock: (payload: string | null) => void;
  activeBlock: string | null;
}

const DraggableContent = ({
  item,
  parentId,
  updateHoveredItem,
  templateBlocks,
  hoveredItem,
  updateBlocks,
  updateActiveBlock,
  activeBlock,
}: IProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const dropRef = useRef<HTMLDivElement | null>(null);
  const [isFirstHalf, setIsFirstHalf] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  UseOnClickOutside(dropRef, (e) => {
    const toolbarElements = document.querySelectorAll('[id^="toolbar-"]');
    const textToolbarElement = document.getElementById('header');

    const clickedInsideToolbar = Array.from(toolbarElements).some((element) =>
      element.contains(e.target as any)
    );

    const clickedInsideTextToolbar =
      textToolbarElement && textToolbarElement.contains(e.target as any);

    if (!!activeBlock && !clickedInsideToolbar && !clickedInsideTextToolbar) {
      updateActiveBlock(null);
    }
  });

  useEffect(() => {
    setIsHovered(hoveredItem === item);
  }, [item, hoveredItem]);

  const handleActive = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    updateActiveBlock(item.id);
  };

  const isInHalfPart = (monitor: any) => {
    const dropTarget = monitor.getClientOffset();
    const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
    if (hoverBoundingRect && hoverBoundingRect.height < 10) {
      return false;
    }

    if (hoverBoundingRect && dropTarget) {
      const hoverMiddleY = hoverBoundingRect.top + hoverBoundingRect.height / 2;
      return dropTarget.y < hoverMiddleY;
    } else {
      return false;
    }
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'MENU_ITEM',
      hover: (_, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          updateHoveredItem(item);
          setIsFirstHalf(isInHalfPart(monitor));
        }
      },
      drop(draggedItem: any, monitor) {
        if (monitor.isOver({ shallow: true })) {
          moveItem(
            item as BookleTemplateBlock,
            draggedItem,
            isFirstHalf,
            templateBlocks,
            updateBlocks,
            parentId
          );
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }),
      }),
    }),
    [item, parentId, isFirstHalf, templateBlocks]
  );

  useEffect(() => {
    if (isOver) {
      updateHoveredItem(item);
    } else {
      updateHoveredItem(undefined);
    }
  }, [isOver, item]);

  return (
    <>
      <div
        ref={(node) => {
          drop(node);
          dropRef.current = node;
        }}
        className={styles.container}
        onClick={handleActive}
      >
        {isDragging && <div className={styles.draggedBlock} />}
        {activeBlock === item.id && (
          <Toolbar item={item} setDragging={setIsDragging} />
        )}
        {isHovered && isFirstHalf && <DropPlace />}
        <div
          className={clsx(styles.contentContainer, {
            [styles.activeBlock]: activeBlock === item.id,
          })}
          style={{
            ...(item.hasColor ? { backgroundColor: item.color } : {}),
            ...(item.hasSpacing
              ? {
                  paddingBottom: item.styles?.blockSpacing?.marginBottom,
                  paddingTop: item.styles?.blockSpacing?.marginTop,
                  paddingLeft: item.styles?.blockSpacing?.marginLeft,
                  paddingRight: item.styles?.blockSpacing?.marginRight,
                }
              : {}),
          }}
        >
          <DraggableItem item={item} />
        </div>
        {isHovered && !isFirstHalf && <DropPlace />}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
  hoveredItem: state.books.bookleTemplateHoveredBlock,
  activeBlock: state.books.activeBlock,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateHoveredItem: (payload: BookleTemplateBlock | undefined) =>
    updateBookleTemplateHoveredBlock(payload),
  updateActiveBlock: (payload: string | null) => updateActiveTemplate(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(DraggableContent);
