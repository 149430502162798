import SidebarItem from './SidebarItem';
import section_1_icon from 'Assets/icons/draggableMenuIcons/section_1_icon.svg';
import section_2_icon from 'Assets/icons/draggableMenuIcons/section_2_icon.svg';
import section_3_icon from 'Assets/icons/draggableMenuIcons/section_3_icon.svg';
import section_4_icon from 'Assets/icons/draggableMenuIcons/section_4_icon.svg';
import text_icon from 'Assets/icons/draggableMenuIcons/text_icon.svg';
import image_icon from 'Assets/icons/draggableMenuIcons/image_icon.svg';
import divider_icon from 'Assets/icons/draggableMenuIcons/divider_icon.svg';
import button_icon from 'Assets/icons/draggableMenuIcons/button_icon.svg';
import spacer from 'Assets/icons/draggableMenuIcons/spacer.svg';
import heading from 'Assets/icons/draggableMenuIcons/heading.svg';

import styles from './Sidebar.module.scss';

export enum MenuItems {
  ONE_SECTION = 'section_1_block',
  TWO_SECTION = 'section_2_block',
  THREE_SECTION = 'section_3_block',
  FOUR_SECTION = 'section_4_block',
  TEXT_BLOCK = 'text_block',
  IMAGE_BLOCK = 'image_block',
  DIVIDER_BLOCK = 'divider_block',
  BUTTON_BLOCK = 'button_block',
  HEADING_BLOCK = 'heading_block',
  SPACER_BLOCK = 'spacer_block',
}

export const sidebarItems = [
  {
    type: MenuItems.ONE_SECTION,
    text: '1 Section',
    icon: section_1_icon,
  },
  {
    type: MenuItems.TWO_SECTION,
    text: '2 Section',
    icon: section_2_icon,
  },
  {
    type: MenuItems.HEADING_BLOCK,
    text: 'Heading',
    icon: heading,
  },
  //NOTE: disabled for now.
  // {
  //   type: MenuItems.THREE_SECTION,
  //   text: '3 Section',
  //   icon: section_3_icon,
  // },
  // {
  //   type: MenuItems.FOUR_SECTION,
  //   text: '4 Section',
  //   icon: section_4_icon,
  // },
  {
    type: MenuItems.TEXT_BLOCK,
    text: 'Text',
    icon: text_icon,
  },
  {
    type: MenuItems.IMAGE_BLOCK,
    text: 'Image',
    icon: image_icon,
  },
  {
    type: MenuItems.DIVIDER_BLOCK,
    text: 'Divider',
    icon: divider_icon,
    styles: {
      height: 2,
    },
  },
  {
    type: MenuItems.SPACER_BLOCK,
    text: 'Spacer',
    icon: spacer,
    isSpacer: true,
    styles: {
      height: 10,
    },
  },
  {
    type: MenuItems.BUTTON_BLOCK,
    text: 'Button',
    icon: button_icon,
  },
];

export const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.container}>
        {sidebarItems.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};
