export const FontFamilies = [
  {
    label: 'Arial',
    value: 'Arial',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Verdana',
    value: 'Verdana',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Helvetica',
    value: 'Helvetica',
    weights: [300, 400, 700],
    webSafe: true,
  },
  {
    label: 'Tahoma',
    value: 'Tahoma',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Trebuchet MS',
    value: 'TrebuchetMS',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Times New Roman',
    value: 'TimesNewRoman',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Garamond',
    value: 'Garamond',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Courier New',
    value: 'CourierNew',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Brush Script MT',
    value: 'BrushScriptMT',
    weights: [400, 700],
    webSafe: true,
  },
  {
    label: 'Roboto',
    value: 'Roboto',
    weights: [400, 700],
  },
  {
    label: 'Roboto Mono',
    value: 'RobotoMono',
    weights: [100, 200, 300, 400, 500, 600, 700],
  },
  {
    label: 'Yusei Magic',
    value: 'YuseiMagic',
    weights: [400],
  },
  {
    label: 'Open Sans',
    value: 'OpenSans',
    weights: [300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Oswald',
    value: 'Oswald',
    weights: [200, 300, 400, 500, 600, 700],
  },
  {
    label: 'Indie Flower',
    value: 'IndieFlower',
    weights: [400],
  },
  {
    label: 'Caveat',
    value: 'Caveat',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'Courgette',
    value: 'Courgette',
    weights: [400],
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Lato',
    value: 'Lato',
    weights: [100, 300, 400, 700, 900],
  },
  {
    label: 'Alegreya',
    value: 'Alegreya',
    weights: [400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Poppins',
    value: 'Poppins',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Raleway',
    value: 'Raleway',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Merriweather',
    value: 'Merriweather',
    weights: [300, 400, 700, 900],
  },
  {
    label: 'Concert One',
    value: 'ConcertOne',
    weights: [400],
  },
  {
    label: 'Work Sans',
    value: 'WorkSans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'BioRhyme',
    value: 'BioRhyme',
    weights: [200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Playfair Display',
    value: 'PlayFairDisplay',
    weights: [400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Rubik',
    value: 'Rubik',
    weights: [300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Cormorant Garamond',
    value: 'CormorantGaramond',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Libre Baskerville',
    value: 'LibreBaskerville',
    weights: [400, 700],
  },
  {
    label: 'Eczar',
    value: 'Eczar',
    weights: [400, 500, 600, 700, 800],
  },
  {
    label: 'Delicious Handrawn',
    value: 'DeliciousHandrawn',
    weights: [400],
  },
  {
    label: 'Josefin Sans',
    value: 'JosefinSans',
    weights: [100, 200, 300, 400, 500, 600, 700],
  },
  {
    label: 'Anton',
    value: 'Anton',
    weights: [400],
  },
  {
    label: 'Bebas Neue',
    value: 'BebasNeue',
    weights: [400],
  },
  {
    label: 'Righteous',
    value: 'Righteous',
    weights: [400],
  },
  {
    label: 'Black Ops One',
    value: 'BlackOpsOne',
    weights: [400],
  },
  {
    label: 'Markazi Text',
    value: 'MarkaziText',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'VT323',
    value: 'VT323',
    weights: [400],
  },
  {
    label: 'Black Han Sans',
    value: 'BlackHanSans',
    weights: [400],
  },
  {
    label: 'Comic Neue',
    value: 'ComicNeue',
    weights: [300, 400, 700],
  },
  {
    label: 'Average Sans',
    value: 'AverageSans',
    weights: [400],
  },
  {
    label: 'Caveat Brush',
    value: 'CaveatBrush',
    weights: [400],
  },
  {
    label: 'Syne',
    value: 'Syne',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'Darker Grotesque',
    value: 'DarkerGrotesque',
    weights: [300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Creepster',
    value: 'Creepster',
    weights: [400],
  },
  {
    label: 'Quicksand',
    value: 'Quicksand',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Jost',
    value: 'Jost',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Manrope',
    value: 'Manrope',
    weights: [200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Archivo',
    value: 'Archivo',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Agbalumo',
    value: 'Agbalumo',
    weights: [400],
  },
  {
    label: 'Inter',
    value: 'Inter',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Edu TAS Beginner',
    value: 'EduTASBeginner',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'Noto Sans',
    value: 'NotoSans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Nunito Sans',
    value: 'NunitoSans',
    weights: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
  },
  {
    label: 'Nunito',
    value: 'Nunito',
    weights: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
  },
  {
    label: 'Ubuntu',
    value: 'Ubuntu',
    weights: [300, 400, 500, 700],
  },
  {
    label: 'PT Sans',
    value: 'PTSans',
    weights: [400, 700],
  },
  {
    label: 'Kenia',
    value: 'Kenia',
    weights: [400],
  },
  {
    label: 'Kanit',
    value: 'Kanit',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Lora',
    value: 'Lora',
    weights: [400, 500, 600, 700, 800],
  },
  {
    label: 'Fira Sans',
    value: 'FiraSans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Mulish',
    value: 'Mulish',
    weights: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
  },
  {
    label: 'Barlow',
    value: 'Barlow',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'DM Sans',
    value: 'DMSans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
  },
  {
    label: 'IBM Plex Sans',
    value: 'IBMPlexSans',
    weights: [100, 200, 300, 400, 500, 600, 700],
  },
  {
    label: 'Inconsolata',
    value: 'Inconsolata',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'PT Serif',
    value: 'PTSerif',
    weights: [400, 700],
  },
  {
    label: 'Titillium Web',
    value: 'TitilliumWeb',
    weights: [200, 300, 400, 600, 700, 900],
  },
  {
    label: 'Noto Serif',
    value: 'NotoSerif',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Heebo',
    value: 'Heebo',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Karla',
    value: 'Karla',
    weights: [200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Mukta',
    value: 'Mukta',
    weights: [200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Libre Franklin',
    value: 'LibreFranklin',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Nanum Gothic',
    value: 'NanumGothic',
    weights: [400, 700, 800],
  },
  {
    label: 'Alumni Sans Inline One',
    value: 'AlumniSansInlineOne',
    weights: [400],
  },
  {
    label: 'Arimo',
    value: 'Arimo',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'Dosis',
    value: 'Dosis',
    weights: [200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Hind Siliguri',
    value: 'HindSiliguri',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Bitter',
    value: 'Bitter',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Source Code Pro',
    value: 'SourceCodePro',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Cabin',
    value: 'Cabin',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'PT Sans Narrow',
    value: 'PTSansNarrow',
    weights: [400, 700],
  },
  {
    label: 'Barlow Condensed',
    value: 'BarlowCondensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Oxygen',
    value: 'Oxygen',
    weights: [300, 400, 700],
  },
  {
    label: 'Dancing Script',
    value: 'DancingScript',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'EB Garamond',
    value: 'EBGaramond',
    weights: [400, 500, 600, 700, 800],
  },
  {
    label: 'Playpen Sans',
    value: 'PlaypenSans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Abel',
    value: 'Abel',
    weights: [400],
  },
  {
    label: 'Assistant',
    value: 'Assistant',
    weights: [200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Space Grotesk',
    value: 'SpaceGrotesk',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Hind',
    value: 'Hind',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Lobster',
    value: 'Lobster',
    weights: [400],
  },
  {
    label: 'Pacifico',
    value: 'Pacifico',
    weights: [400],
  },
  {
    label: 'Exo 2',
    value: 'Exo2',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'DM Serif Display',
    value: 'DMSerifDisplay',
    weights: [400],
  },
  {
    label: 'Teko',
    value: 'Teko',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Crimson Text',
    value: 'CrimsonText',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'Comfortaa',
    value: 'Comfortaa',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Prompt',
    value: 'Prompt',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Signika Negative',
    value: 'SignikaNegative',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Outfit',
    value: 'Outfit',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Rajdhani',
    value: 'Rajdhani',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Maven Pro',
    value: 'MavenPro',
    weights: [400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Sometype Mono',
    value: 'SometypeMono',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'IBM Plex Mono',
    value: 'IBMPlexMono',
    weights: [100, 200, 300, 400, 500, 600, 700],
  },
  {
    label: 'Slabo 27px',
    value: 'Slabo27px',
    weights: [400],
  },
  {
    label: 'Varela Round',
    value: 'VarelaRound',
    weights: [400],
  },
  {
    label: 'Fjalla One',
    value: 'FjallaOne',
    weights: [400],
  },
  {
    label: 'Red Hat Display',
    value: 'RedHatDisplay',
    weights: [300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Arvo',
    value: 'Arvo',
    weights: [400, 700],
  },
  {
    label: 'M PLUS Rounded 1c',
    value: 'MPLUSRounded1c',
    weights: [100, 300, 400, 500, 700, 800, 900],
  },
  {
    label: 'Public Sans',
    value: 'PublicSans',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Overpass',
    value: 'Overpass',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Asap',
    value: 'Asap',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Shadows Into Light',
    value: 'ShadowsIntoLight',
    weights: [400],
  },
  {
    label: 'Satisfy',
    value: 'Satisfy',
    weights: [400],
  },
  {
    label: 'Abril Fatface',
    value: 'AbrilFatface',
    weights: [400],
  },
  {
    label: 'Play',
    value: 'Play',
    weights: [400, 700],
  },
  {
    label: 'Fira Sans Condensed',
    value: 'FiraSansCondensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Tajawal',
    value: 'Tajawal',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Questrial',
    value: 'Questrial',
    weights: [400],
  },
  {
    label: 'Asap Condensed',
    value: 'AsapCondensed',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Barlow Semi Condensed',
    value: 'BarlowSemiCondensed',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Chakra Petch',
    value: 'ChakraPetch',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Zilla Slab',
    value: 'ZillaSlab',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Signika',
    value: 'Signika',
    weights: [300, 400, 500, 600, 700],
  },
  {
    label: 'Nanum Myeongjo',
    value: 'NanumMyeongjo',
    weights: [400, 700, 800],
  },
  {
    label: 'Domine',
    value: 'Domine',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'Catamaran',
    value: 'Catamaran',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Source Sans 3',
    value: 'SourceSans3',
    weights: [200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Almarai',
    value: 'Almarai',
    weights: [300, 400, 700, 800],
  },
  {
    label: 'Yanone Kaffeesatz',
    value: 'YanoneKaffeesatz',
    weights: [200, 300, 400, 500, 600, 700],
  },
  {
    label: 'IBM Plex Serif',
    value: 'IBMPlexSerif',
    weights: [100, 200, 300, 400, 500, 600, 700],
  },
  {
    label: 'Lexend',
    value: 'Lexend',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Lilita One',
    value: 'LilitaOne',
    weights: [400],
  },
  {
    label: 'M PLUS 1p',
    value: 'MPLUS1p',
    weights: [100, 300, 400, 500, 700, 800, 900],
  },
  {
    label: 'Didact Gothic',
    value: 'DidactGothic',
    weights: [400],
  },
  {
    label: 'Archivo Narrow',
    value: 'ArchivoNarrow',
    weights: [400, 500, 600, 700],
  },
  {
    label: 'Plus Jakarta Sans',
    value: 'PlusJakartaSans',
    weights: [200, 300, 400, 500, 600, 700, 800],
  },
  {
    label: 'Permanent Marker',
    value: 'PermanentMarker',
    weights: [400],
  },
  {
    label: 'Acme',
    value: 'Acme',
    weights: [400],
  },
  {
    label: 'Geologica',
    value: 'Geologica',
    weights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Figtree',
    value: 'Figtree',
    weights: [300, 400, 500, 600, 700, 800, 900],
  },
  {
    label: 'Archivo Black',
    value: 'ArchivoBlack',
    weights: [400],
  },
];
