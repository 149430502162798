import { useMemo } from 'react';
import { Descendant } from 'slate';
import { ReactComponent as CopyIcon } from 'Assets/icons/Copy.svg';
import NodeTextEditor from 'Editors/NodeTextEditor/NodeTextEditor';
import { convertBlocksToString, convertTextToBlocks } from '../helpers';
import TemplateNode, { INode, INodeEvent } from './Node';
import { Select } from 'antd';

import styles from './Node.module.scss';

export interface INodeGenerateText extends INode {
  prompt: string;
}

interface IProps {
  node: INodeGenerateText;
  variables?: string[];
  onChange: (node: INode) => void;
  onEvent: (event: INodeEvent) => void;
}


const GenerateText = (props: IProps) => {
  const initialPromptValue = useMemo(() => {
    return convertTextToBlocks(props.node.prompt);
  }, [props.node.prompt]);

  const handleTextChange = (blocks: Descendant[]) => {
    const finalText = convertBlocksToString(blocks);

    const newNode = { ...props.node, prompt: finalText };
    props.onChange(newNode);
  };

  return (
    <TemplateNode
      node={props.node}
      onChange={props.onChange}
      onEvent={props.onEvent}
      headerColor="#73adbf"
      icon={<CopyIcon />}
    >
      <NodeTextEditor
        editorClassName={styles.textEditor}
        tagsList={props.variables ?? []}
        initialValue={initialPromptValue}
        onChange={handleTextChange}
      />
    </TemplateNode>
  );
};

export default GenerateText;
