import {
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  LegacyRef,
  ChangeEvent,
} from 'react';
import { SmartList } from 'Pages/PageContacts/Helper/types';
import { ReactComponent as Edit } from 'Assets/icons/edit2.svg';
import { ReactComponent as Close } from 'Assets/icons/close2.svg';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Input from 'UILib/Input/Input';

import styles from './SmartListItem.module.scss';

interface IProps {
  smartList: SmartList;
  setSmartList: (smartList: string) => void;
  removeSmartListPopup: (open: boolean) => void;
  updateSmartList: (id: string, name: string) => void;
}

const SmartListItem = ({
  smartList,
  setSmartList,
  removeSmartListPopup,
  updateSmartList,
}: IProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [smartListName, setSmartListName] = useState<string>(smartList.name);
  const [contentWidth, setContentWidth] = useState<number>(0);

  const inputRef = useRef<HTMLDivElement>(null);
  const labelRef: LegacyRef<HTMLDivElement> = useRef(null);

  UseOnClickOutside(inputRef, () => onOutsideClick());

  useEffect(() => {
    if (editMode) {
      const inputContainer = document.getElementsByClassName(styles.input);
      if (!inputContainer || !inputContainer[0]) return;

      const inputElement = inputContainer[0].querySelector('input');
      if (!inputElement) return;

      inputElement.focus();
    } else {
      if (!labelRef.current) return;

      setContentWidth(labelRef.current.offsetWidth);
    }
  }, [editMode]);

  useEffect(() => {
    if (labelRef.current) {
      setContentWidth(labelRef?.current?.offsetWidth);
    }
  }, [labelRef?.current?.offsetWidth]);

  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    setContentWidth(event.target.value.length * 7);

    setSmartListName(event.target.value);
  };

  const onOutsideClick = useCallback(async () => {
    setEditMode(false);
    if (smartList.name !== smartListName) {
      await graphQlCall({
        queryTemplateObject: queries.UPDATE_SMART_LIST,
        values: {
          id: smartList._id,
          name: smartListName,
        },
        headerType: 'USER-AUTH',
      });

      updateSmartList(smartList._id, smartListName);
    }
  }, [smartListName, smartList._id, updateSmartList, smartList.name]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setEditMode(false);
      if (onOutsideClick) onOutsideClick();
    }
  };

  return (
    <div className={styles.option}>
      {editMode ? (
        <div ref={inputRef}>
          <Input
            className={styles.input}
            onClick={(e) => !!editMode && e.stopPropagation()}
            value={smartListName}
            onChange={handleChangeText}
            disabled={!editMode}
            onKeyDown={handleKeyDown}
            style={{ width: contentWidth + 10 }}
          />
        </div>
      ) : (
        <div className={styles.label} ref={labelRef}>
          {smartListName}
        </div>
      )}

      {!editMode && (
        <div className={styles.icons} onClick={(e) => e.stopPropagation()}>
          <Edit className={styles.edit} onClick={() => setEditMode(true)} />
          <Close
            className={styles.close}
            onClick={() => {
              setSmartList(smartList._id);
              removeSmartListPopup(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SmartListItem;
