import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import {
  BlocksHistory,
  defaultTemplateStyles,
  IBookleTemplateBlockStyles,
  IBookleTemplateEditor,
} from 'store/books/booksReducer';
import {
  clearBlocksHistory,
  redoBlocks,
  undoBlocks,
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';

import { base64ToFile } from 'utils/helpers';
import { api, getToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';

import GenerationPreview from 'Pages/PageGenerationEditor/GenerationPreview/GenerationPreview';
import EmailTestModal from 'Components/EmailTestModal/EmailTestModal';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import Button from 'UILib/Button/Button';
import queries from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import { BookleTemplateBlock, IBroadcast } from 'types';
import { Preview2HTML } from 'Pages/PageGenerationEditor/Draggable/utils';
import { BroadcastMenuType } from 'Pages/Broadcasts/types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';
import UndoRedoButtons from 'Components/UndoRedoButtons/UndoRedoButtons';
import CompleteBroadcastCampaign from './CompleteBroadcastCampaign/CompleteBroadcastCampaign';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';

import styles from './BroadcastEditor.module.scss';
import { getLayoutThumbnail } from 'utils/Utils';

interface IProps {
  handleGoBack: () => void;
  templateTextEditor: IBookleTemplateEditor;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
  broadcast: string;
  mailDraftId: string;
  generationTaskId?: string;
  emailSubject: string;
  broadcastData?: IBroadcast;
  blocksHistory: BlocksHistory;
  redoBlocks: () => void;
  undoBlocks: () => void;
  clearBlocksHistory: () => void;
}

const BroadcastEditor = ({
  handleGoBack,
  templateTextEditor,
  updateTextEditor,
  templateBlockStyles,
  templateBlocks,
  updateBlockStyles,
  updateBlocks,
  broadcast,
  mailDraftId,
  generationTaskId,
  emailSubject,
  broadcastData,
  blocksHistory,
  redoBlocks,
  undoBlocks,
  clearBlocksHistory,
}: IProps) => {
  const [showSideMenu, setShowSideMenu] = useState<BroadcastMenuType | boolean>(
    false
  );
  const [showTestEmailModal, setShowTestEmailModal] = useState<boolean>(false);
  const [showLaunchPage, setShowLaunchPage] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('Email Subject');
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { broadcastId } = useParams<{ broadcastId: string }>();

  const history = useHistory();

  const ref = useRef<HTMLDivElement>(null);
  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as any));

    if (
      ref.current &&
      !ref.current.contains(e.target as any) &&
      !clickedInsideTextEditor
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  const toggleSideMenu = (menuType?: BroadcastMenuType) => {
    if (showSideMenu) {
      setShowSideMenu(false);
    }
    setShowSideMenu(menuType || false);
  };

  useEffect(() => {
    if (emailSubject) {
      setSubject(emailSubject);
    }

    return () => {
      clearBlocksHistory();
      updateBlockStyles(defaultTemplateStyles);
    };

  }, []);

  const handleSaveScreenshot = async (broadcast: string) => {
    if (broadcast) {
      const dataUrl = await getLayoutThumbnail();
      const data = new FormData();
      data.append('id', broadcast);
      data.append('file', base64ToFile(dataUrl, subject));
      await api(`${PAGECRAFT_API_URL}/broadcast/upload-image`, 'POST', data, {
        Authorization: getToken(),
      });
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const convertor = new Preview2HTML();
      const blocksHtmlContent = convertor.setCorrespondingBlocks(
        templateBlocks,
        templateBlockStyles,
        false
      );
      setHtmlContent(blocksHtmlContent);

      const mailDraftResponse = await graphQlCall({
        queryTemplateObject: queries.UPDATE_MAIL_DRAFT_MUTATION,
        values: {
          id: mailDraftId,
          text: '',
          subject,
          data: JSON.stringify({
            styles: templateBlockStyles,
            blocks: templateBlocks,
          }),
          html: blocksHtmlContent,
        },
        headerType: 'USER-AUTH',
      });

      await handleSaveScreenshot(broadcast);

      if (!broadcastId) {
        history.push(`/console/broadcasts/${broadcast}/edit`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubjectChange = async (subject: string) => {
    setSubject(subject);
  };
  const handleLaunch = async () => {
    try {
      setLoading(true);
      await handleSave();
      setShowLaunchPage(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTestEmail = () => {
    setShowTestEmailModal(true);
  };

  const handleDraggableSidebarEvent = (isSidebarOpen: boolean) => {
    if (isSidebarOpen) {
      setShowSideMenu(BroadcastMenuType.draggableComponentMenu);
      return;
    }
    setShowSideMenu(false);
  };

  if (showLaunchPage) {
    return (
      <CompleteBroadcastCampaign
        subject={subject}
        onGoBack={() => setShowLaunchPage(false)}
        onEmailSubjectChanged={handleEmailSubjectChange}
        maildraftId={mailDraftId}
        htmlContent={htmlContent}
        broadcastId={broadcast}
        broadcastData={broadcastData}
        broadcastStyles={templateBlockStyles}
      />
    );
  }

  return (
    <>
      <EmailTestModal
        open={showTestEmailModal}
        emailSubject={subject}
        onClose={() => setShowTestEmailModal(false)}
      />
      <div className={styles.BroadCastEditor}>
        {/* {!showSideMenu && (
          <div className={styles.buttonsContainer}>
            <Button
              appearance="solid"
              width={40}
              height={40}
              className={styles.menuButton}
              onClick={() => toggleSideMenu(BroadcastMenuType.templateMenu)}
            >
              <Episodes />
            </Button>
          </div>
        )} */}
        <div ref={ref} id="header">
          <EditHeader
            showConfirmButton={true}
            pageName={subject}
            onTitleChange={setSubject}
            editableTitle
            title="Subject"
            handleConfirm={handleLaunch}
            handleGoBack={handleGoBack}
            buttonPlaceholder={
              <div className={styles.confirmButtonPlaceholder}>
                {loading ? (
                  <CircleLoader color="#ffffff" size={20} />
                ) : (
                  <>
                    Continue
                    <ArrowRight
                      className={styles.placeholderIcon}
                      width={22}
                      height={18}
                    />
                  </>
                )}
              </div>
            }
            additionalButtons={
              <div className={styles.additionalButtons}>
                <UndoRedoButtons
                  undo={undoBlocks}
                  redo={redoBlocks}
                  disabledUndo={blocksHistory.activeIndex === 0}
                  disabledRedo={
                    !blocksHistory.blocks.length ||
                    blocksHistory.blocks.length - 1 ===
                      blocksHistory.activeIndex
                  }
                />
                <Button
                  appearance="stroke"
                  height={40}
                  prefixIcon={
                    <div className={styles.emailPrefixContainer}>
                      Test Email
                      <Email
                        className={styles.ghostIcon}
                        width={22}
                        height={18}
                      />
                    </div>
                  }
                  onClick={handleTestEmail}
                />
                <Button
                  appearance="stroke"
                  height={40}
                  prefixIcon={
                    <div className={styles.emailPrefixContainer}>Save</div>
                  }
                  onClick={handleSave}
                />
              </div>
            }
            showCustomHeader={!!templateTextEditor.editor}
            customHeader={
              <TextEditorToolbar
                editor={templateTextEditor.editor}
                selection={templateTextEditor.selection}
                showListItems={false}
              />
            }
          />
        </div>
        <div className={styles.EditorContainer}>
          {/* <div
            className={`${styles.SideMenu} ${
              showSideMenu !== BroadcastMenuType.templateMenu
                ? styles.collapsed
                : ''
            }`}
          >
            <BroadcastSideMenu
              isOpen={!!showSideMenu}
              onClose={() => toggleSideMenu()}
            />
          </div> */}
          <div className={styles.Editor}>
            <GenerationPreview
              isSidebarHidden={showSideMenu === BroadcastMenuType.templateMenu}
              onSidebarToggle={handleDraggableSidebarEvent}
              generationTaskId={generationTaskId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
  blocksHistory: state.books.blocksHistory,
});

const mapDispatchToProps = {
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
  undoBlocks: () => undoBlocks(),
  redoBlocks: () => redoBlocks(),
  clearBlocksHistory: () => clearBlocksHistory(),
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastEditor);
