import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { graphQlCall } from 'graphql/utils';
import { getUserId } from 'utils/Utils';
import { RootState } from 'store/rootReducer';
import { getDomain } from 'utils/helpers';
import {
  IAgencyDetails,
  IFunnelTemplate,
  IGeneralProject,
  ITemplateCategory,
} from 'types';
import QUERIES from 'graphql/queries';
import Spinner from 'Components/Common/Spinner/Spinner';
import Loader from 'UILib/Loader/Loader';
import Modal from 'Components/Common/Modal/Modal';
import Tabs from './Tabs/Tabs';
import Category from './Category/Category';

import s from './Templates.module.scss';

interface IProps {
  firstFunnelLoad: boolean;
  onClose: (projectId?: string) => void;
  project: IGeneralProject;
  onSelect: (template: IFunnelTemplate) => void;
  handleSpinnerOnRedirect: () => void;
  show: boolean;
  userDetails: IAgencyDetails;
}

const Templates = (props: IProps) => {
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const [templates, setTemplates] = useState<Record<string, IFunnelTemplate[]>>(
    {}
  );

  const hasAutoSaasAccess =
    props.userDetails.scopes?.includes('SAAS_OWNER') ||
    !!props.userDetails.saasId;

  const modalRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [
    isOpenModalCreatePageWithAI,
    setIsOpenModalCreatePageWithAI,
  ] = useState(false);
  const [isPageWithAiSaving, setIsPageWithAiSaving] = useState(false);

  const userId = getUserId();

  useEffect(() => {
    setIsOpenModalCreatePageWithAI(false);
    setIsPageWithAiSaving(false);

    const handleMessage = (event: MessageEvent) => {
      const data = event.data;

      if (data.status === 'success page create') {
        setIsPageWithAiSaving(true);
        if (iframeRef.current) {
          iframeRef.current.contentWindow?.postMessage({
            message: 'need save',
          });
        }
      }
      if (data.status === 'saveComplete') {
        window.location.replace(data.url);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    setTemplatesLoading(true);
    if (!props.userDetails.scopes) return;

    if (hasAutoSaasAccess) {
      graphQlCall({
        queryTemplateObject: QUERIES.GET_SAAS_TEMPLATES_AND_CATEGORIES,
        headerType: 'USER-AUTH',
      }).then((response) => {
        const { categories, templates } = response;

        const blankPage = templates.find(
          (temp: IFunnelTemplate) => temp.name === 'Blank Page'
        );

        const groupedTemplates: Record<
          string,
          IFunnelTemplate[]
        > = templates.reduce(
          (obj: Record<string, IFunnelTemplate[]>, temp: IFunnelTemplate) => {
            if (!temp.category) return obj;

            const categoryName = categories.find(
              (cat: ITemplateCategory) => cat._id === temp.category
            )?.name;

            if (!categoryName) return obj;

            if (!obj[categoryName]) obj[categoryName] = [];
            obj[categoryName].push(temp);

            return obj;
          },
          Object.create(null)
        );
        Object.keys(groupedTemplates).forEach((key) => {
          groupedTemplates[key].sort((temp) =>
            temp.type === 'aipage' ? -1 : 1
          );
          if (blankPage) groupedTemplates[key].push(blankPage);
        });
        setTemplates(groupedTemplates);
      });
    } else {
      graphQlCall({
        queryTemplateObject: QUERIES.GET_TEMPLATES_WITH_CATEGORIES,
        headerType: 'USER-AUTH',
      }).then((fetchedTemplates: IFunnelTemplate[]) => {
        const blankPage = fetchedTemplates.find(
          (temp) => temp.name === 'Blank Page'
        );

        const groupedTemplates: Record<
          string,
          IFunnelTemplate[]
        > = fetchedTemplates.reduce((obj, temp) => {
          if (!temp.category) return obj;

          if (!obj[temp.category]) obj[temp.category] = [];
          obj[temp.category].push(temp);

          return obj;
        }, Object.create(null));

        Object.keys(groupedTemplates).forEach((key) => {
          groupedTemplates[key].sort((temp) =>
            temp.type === 'aipage' ? -1 : 1
          );
          if (blankPage) groupedTemplates[key].push(blankPage);
        });

        setTemplates(groupedTemplates);
      });
    }
    setTemplatesLoading(false);
  }, [hasAutoSaasAccess, props.userDetails]);

  // const handleClickOutside = () => {
  // if (!props.firstFunnelLoad && (props.project.pages.length > 0)) {
  //   props.onClose()
  // }
  // };
  // UseOnClickOutside(modalRef, () => handleClickOutside());

  const handleSubmit = (template: IFunnelTemplate) => {
    if (template.type === 'template') {
      props.onSelect(template);
    } else {
      const { domain } = getDomain();
      const templateLink = `//${domain}/edit/ai-page/${template.page}?projectId=${props.project._id}`;
      window.location.assign(templateLink);
    }
  };

  // const getTemplates = (categoryId: string) => {
  //   let output = props.templates.filter(
  //     (template) => template.category === categoryId
  //   );
  //   // let index = output.findIndex((element) => { //TODO: Workaround to place AI Page in front of Template list
  //   //   return element.name == 'AI Page';
  //   // })
  //   // if (index > 0) {
  //   //   let blank = output[index];
  //   //   output[index] = output[0];
  //   //   output[0] = blank;
  //   // }
  //   return output;
  // };

  // const handleBlankPage = () => {
  //   const blankTemplate = props.templates.find((t) => t.name == 'Blank Page');
  //   if (blankTemplate) {
  //     props.onSelect(blankTemplate);
  //     props.onClose();
  //     props.handleSpinnerOnRedirect();
  //   }
  // };
  const handleAIPage = () => {
    setIsOpenModalCreatePageWithAI(true);
  };

  const { domain } = getDomain();
  const iframeSrc = `//${domain}/edit/generate/${userId}/new/option?projectId=${props.project._id}`;

  const handleCloseIframe = () => {
    props.onClose(props.project._id);
  };

  return (
    <Modal displayModal={props.show}>
      {!isOpenModalCreatePageWithAI ? (
        <div className={s.modalBlock} ref={modalRef}>
          <div
            className={s.modalHeader}
            style={{ marginTop: props.firstFunnelLoad ? '40px' : '' }}
          >
            {/* {props.firstFunnelLoad ? null : <button className={s.closeIcon} onClick={() => props.onClose()}/>} */}
            <button className={s.closeIcon} onClick={() => props.onClose()} />
          </div>
          <div className={s.modalBody}>
            {templatesLoading ? (
              <div className={s.loader}>
                <Loader size={32} color="#4957d8" />
              </div>
            ) : (
              Object.keys(templates).length > 0 && (
                <Tabs
                  tabs={Object.keys(templates)
                    .sort((temp) => (temp === 'Lead Pages' ? -1 : 1))
                    .map((key) => ({
                      title: key,
                      content: (
                        <Category
                          handleSpinnerOnRedirect={
                            props.handleSpinnerOnRedirect
                          }
                          onClose={props.onClose}
                          onSubmit={handleSubmit}
                          templates={templates[key]}
                        />
                      ),
                    }))}
                  contentClassName={s.tabsContent}
                />
              )
            )}
          </div>
        </div>
      ) : (
        <div className={s.modalBlock} ref={modalRef}>
          <div className={s.iframeWrapper}>
            <button
              className={s.closeIcon}
              onClick={() => {
                setIsOpenModalCreatePageWithAI(false);
                handleCloseIframe();
              }}
            />
            <iframe
              src={iframeSrc}
              width="100%"
              height="100%"
              ref={iframeRef}
              style={isPageWithAiSaving ? { opacity: '0' } : {}}
            ></iframe>
            <div
              className={s.iframeSpinnerBlock}
              style={{ display: isPageWithAiSaving ? 'block' : 'none' }}
            >
              <Spinner size={100} />
            </div>
          </div>
        </div>
      )}
      {/* <Modal displayModal={isOpenModalCreatePageWithAI} closeModal={()=>setIsOpenModalCreatePageWithAI(false)}>
              <div className={s.iframeModalWrapper} onClick={()=>setIsOpenModalCreatePageWithAI(false)}>
                <div className={s.iframeWrapper}>
                  <button className={s.closeIcon} onClick={() => setIsOpenModalCreatePageWithAI(false)}/>

                  <iframe src={iframeSrc} width='100%' height='755px' ref={iframeRef} style={isPageWithAiSaving ? {opacity:'0'} : {}}></iframe>
                  <div className={s.iframeSpinnerBlock} style={isPageWithAiSaving ? {display:'block'} : {display:'none'}}>
                    <Spinner size={100} />
                  </div>
                </div>
              </div>
              
      </Modal> */}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.agencyDetails,
});

export default connect(mapStateToProps, null)(Templates);
