import React, { useState, useRef } from 'react';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as PlusIcon } from 'Assets/icons/PlusContacts.svg';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import { ReactComponent as TagIcon } from 'Assets/icons/Tag.svg';
import { ContactItemProps, Person } from 'Pages/PageContacts/Helper/types';
import { graphQlCall } from 'graphql/utils';
import { formatDate } from 'utils/helpers';
import CircleAvatar from 'Pages/PageContacts/Helper/Avatar';
import QUERIES from 'graphql/queries';
import Button from 'UILib/Button/Button';

import s from 'Pages/PageContacts/ContactItem/ContactItem.module.scss';

export const ContactItem = (props: ContactItemProps) => {
  const [editingField, setEditingField] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [tagInput, setTagInput] = useState<string>('');
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [showAddTagInput, setShowAddTagInput] = useState<boolean>(false);

  const saveBtnRef = useRef<HTMLButtonElement>(null);
  const tagRefs = useRef<(HTMLSpanElement | null)[]>([]);

  const handleCheckboxChange = (id: number) => {
    props.setSelectedCheckboxes((prevSelectedCheckboxes) => {
      let updatedCheckboxes;
      if (prevSelectedCheckboxes.includes(id)) {
        updatedCheckboxes = prevSelectedCheckboxes.filter((i) => i !== id);
      } else {
        updatedCheckboxes = [...prevSelectedCheckboxes, id];
      }
      if (updatedCheckboxes.length === props.people.length) {
        props.setSelectAll(true);
      } else {
        props.setSelectAll(false);
      }
      return updatedCheckboxes;
    });
  };

  const handleDropdownClick = () => {
    props.fetchUniqueTags();
    setDropdownVisible(!dropdownVisible);
  };

  const handleAddTagToSelected = (tag: {
    _id: string;
    name: string;
    createdAt: string;
  }) => {
    props.addTagToPerson(props.person?._id, tag.name);
    setTagInput('');
    setDropdownVisible(false);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.relatedTarget !== saveBtnRef.current) {
      setEditingField(null);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const saveEditedField = async (
    _id: number,
    field: string,
    value: string,
    people: Person[],
    setPeople: React.Dispatch<React.SetStateAction<Person[]>>,
    setIsEditing: React.Dispatch<React.SetStateAction<number | null>>
  ) => {
    let variables: any = { id: _id.toString() };
    let updatedField: any;

    if (['name', 'addr', 'country'].includes(field)) {
      const person = people.find((p) => p._id === _id);
      if (person) {
        updatedField = {
          data: JSON.stringify({
            ...person.data,
            [field]: value,
          }),
        };
      }
    } else {
      updatedField = { [field]: value };
    }

    variables = { ...variables, ...updatedField };

    try {
      const response = await performGraphQlCallEdit(_id.toString(), variables);
      const updatedPeople = people.map((p) => {
        if (p._id === _id) {
          if (['name', 'addr', 'country'].includes(field)) {
            return { ...p, data: { ...p.data, [field]: value } };
          } else {
            return { ...p, [field]: value };
          }
        }
        return p;
      });
      setPeople(updatedPeople);
      setIsEditing(null);
      setEditingField(null);
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };

  const performGraphQlCallEdit = async (
    _id: string | undefined,
    updatedFields: any
  ): Promise<Person> => {
    return await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_CONTACT,
      values: updatedFields,
      headerType: 'USER-AUTH',
    });
  };

  const handleFieldClick = (field: string, value: string) => {
    setEditingField(field);
    setInputValue(value);
  };

  const renderField = (fieldName: string, fieldValue: string | undefined) => (
    <input
      className={s[fieldName.toLowerCase()]}
      onBlur={handleInputBlur}
      type={fieldName === 'Email' ? 'email' : 'text'}
      onClick={() => handleFieldClick(fieldName, fieldValue || '')}
      value={editingField === fieldName ? inputValue : fieldValue || ''}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );

  const handleShowAddTagInput = () => {
    setShowAddTagInput(true);
  };

  const handleMenuClose = () => {
    setShowAddTagInput(false);
  };

  const menu = (
    <>
      <Menu className={s.tagMenu}>
        {props.uniqueTags.map(
          (tag: { _id: string; name: string; createdAt: string }) => (
            <Menu.Item
              key={tag._id}
              className={s.tagDisplay}
              onClick={() => handleAddTagToSelected(tag)}
            >
              {tag.name}
            </Menu.Item>
          )
        )}
      </Menu>
      <Menu className={s.secondMenu}>
        <Menu.Divider />
        <Menu.Item key="2">
          <div className={s.popupInput}>
            {!showAddTagInput ? (
              <>
                <button className={s.addTag} onClick={handleShowAddTagInput}>
                  <PlusIcon />
                  Add
                </button>
                <button
                  onClick={props.handleSwichToSmart}
                  className={s.setupBtn}
                >
                  <TagIcon />
                  Setup
                </button>
              </>
            ) : (
              <div
                style={{
                  border: `1px solid ${isFocused ? '#6674F4' : '#A0A0A0'}`,
                }}
              >
                <input
                  placeholder="Add New Tag"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                />
                <button
                  className={s.inputButton}
                  onClick={() =>
                    handleAddTagToSelected({
                      _id: '',
                      name: tagInput,
                      createdAt: '',
                    })
                  }
                >
                  <PlusIcon />
                </button>
              </div>
            )}
          </div>
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <>
      <div className={s.person} key={props.person._id}>
        <input
          type="checkbox"
          checked={props.selectedCheckboxes.includes(props.person._id)}
          onChange={() => handleCheckboxChange(props.person._id)}
        />
        <CircleAvatar
          name={props.person.data?.name}
          email={props.person.email}
        />
        {renderField('Name', props.person.data?.name)}
        {editingField === 'Name' && (
          <Button
            disabled={!inputValue.trim()}
            height={40}
            reference={saveBtnRef}
            onClick={() => {
              if (!inputValue.trim()) return;
              saveEditedField(
                props.person._id,
                'name',
                inputValue,
                props.people,
                props.setPeople,
                setIsEditing
              );
            }}
          >
            Rename
          </Button>
        )}
        {renderField('Email', props.person.email)}
        {editingField === 'Email' && (
          <Button
            disabled={!inputValue.trim()}
            height={40}
            reference={saveBtnRef}
            onClick={() => {
              if (!inputValue.trim()) return;
              saveEditedField(
                props.person._id,
                'email',
                inputValue,
                props.people,
                props.setPeople,
                setIsEditing
              );
            }}
          >
            Update Email
          </Button>
        )}
        {renderField('Phone', props.person?.phone)}
        {editingField === 'Phone' && (
          <Button
            disabled={!inputValue.trim()}
            height={40}
            reference={saveBtnRef}
            onClick={() => {
              if (!inputValue.trim()) return;
              saveEditedField(
                props.person?._id,
                'phone',
                inputValue,
                props.people,
                props.setPeople,
                setIsEditing
              );
            }}
          >
            Update Phone
          </Button>
        )}
        {renderField('Country', props.person?.data?.country)}
        {editingField === 'Country' && (
          <Button
            disabled={!inputValue.trim()}
            height={40}
            reference={saveBtnRef}
            onClick={() => {
              if (!inputValue.trim()) return;
              saveEditedField(
                props.person?._id,
                'country',
                inputValue,
                props.people,
                props.setPeople,
                setIsEditing
              );
            }}
          >
            Update Country
          </Button>
        )}
        {renderField('Address', props.person?.data?.addr)}
        {editingField === 'Address' && (
          <Button
            disabled={!inputValue.trim()}
            height={40}
            reference={saveBtnRef}
            onClick={() => {
              if (!inputValue.trim()) return;
              saveEditedField(
                props.person?._id,
                'addr',
                inputValue,
                props.people,
                props.setPeople,
                setIsEditing
              );
            }}
          >
            Update Address
          </Button>
        )}
        <div className={s.created}>{formatDate(props.person?.createdAt)}</div>
        <div className={s.created}>{props.person?.funnel?.name}</div>
        <div className={s.tagsCover}>
          <div className={s.tags}>
            {props.person?.tags?.map((tag, tagIndex) => (
              <span
                key={tagIndex}
                className={s.tag}
                ref={(el) => (tagRefs.current[props.person?._id] = el)}
              >
                {tag.name}{' '}
                <button
                  onClick={() => props.deleteTag(props.person?._id, tagIndex)}
                >
                  <CrossIcon className={s.cross} />
                </button>
              </span>
            ))}
          </div>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            visible={dropdownVisible}
            onVisibleChange={(visible) => {
              setDropdownVisible(visible);
              if (!visible) {
                handleMenuClose();
              }
            }}
            className={s.dropdown}
          >
            <button onClick={handleDropdownClick}>
              <PlusIcon className={s.blackPlus} />
            </button>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
