export const GET_MY_SASS_DESIGN = {
  operationName: 'getMySaasDesign',
  query: `query getMySaasDesign {
            getMySaasDesign {
                colorScheme
                domain
                logoUrl
                faviconUrl
                name
                helpCenterUrl
                alerts {
                    success
                    successHover
                    error
                    waiting
                }
                sideBar {
                    background
                    appHover
                    appSelected
                    divider
                    text
                    selectedText
                }
                accent {
                    accent
                    accentHover
                    darkAccent
                    darkAccentHover
                }
                apps {
                    name
                    key
                    enabled
                }
            }
        }`,
};
