import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { IBook, IUserProduct } from 'types';
import s from './PageBooks.module.scss';
import { getDomain } from 'utils/helpers';
import BookCard from './BookCard/BookCard';
import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import FreeCreditsPopup, {
  FreeCreditsPopupModes,
} from 'Components/FreeCreditsPopup/FreeCreditsPopup';
import FreeBookBanner from 'Components/FreeBookBanner/FreeBookBanner';
import burgerSvg from '../../Assets/icons/burger.svg';
import clsx from 'clsx';
import { validateUser } from 'utils/Utils';
import { bookSettingsPopupAction } from 'store/books/booksActions';
import ExportBookPopup from './Popups/ExportBook/ExportBookPopup';
import SettingsPopup from './Popups/SettingsPopup/SettingsPopup';
import { IUserDetails } from '../../types';
import UpdatePlanPopup from 'Components/projects/popups/UpdatePlanPopup';
import Pagination from 'UILib/Pagination/Pagination';
import StartVideoBlock from 'Components/Common/StartVideoBlock/StartVideoBlock';
import Credits from 'Components/Credits/Credits';
import SideMenu from 'Components/SideMenu/SideMenu';
import BooksSkeleton from 'Components/Skeletons/BooksSkeleton/BooksSkeleton';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import { IAppsNames } from 'store/autosaas/autosaasReducer';
import { APPS } from 'Components/AutoSaas/constants';

interface IProps {
  userDetails: IUserDetails;
  triggerSettingsPopup: (payload: {
    open?: boolean;
    initialState?: IUserProduct;
  }) => void;
  autoSassAppsNames?: IAppsNames[];
}

const PageBooks = (props: IProps) => {
  const [userEmail, setUserEmail] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [booksData, setBooksData] = useState<IBook[]>([]);
  const [booksLoading, setBooksLoading] = useState<boolean>(true);
  const [editingBookId, setEditingBookId] = useState<null | string>(null);
  const [exportFormat, setExportFormat] = useState<null | string>(null);
  const [initialDownloadLink, setInitialDownloadLink] = useState<null | string>(
    null
  );
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [freeCreditsPopupVisible, setFreeCreditsPopupVisible] = useState(false);
  const [upgradePopupVisible, setUpgradePopupVisible] = useState(false);
  const [freeCreditsPopupMode, setFreeCreditsPopupMode] = useState(
    FreeCreditsPopupModes.Default
  );
  const [totalBooks, setTotalBooks] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [searchedText, setSearchedText] = useState<string>('');
  const [pageName, setPageName] = useState<string>('Book');

  const sortOptions = [
    {
      label: 'Last Update',
      value: 'updatedAt',
    },
    {
      label: `Book Name`,
      value: 'title',
    },
  ];

  useEffect(() => {
    if (!!props.autoSassAppsNames?.length) {
      setPageName(
        props.autoSassAppsNames.find((e) => e.key === APPS.BOOKLE)?.name ||
          'Book'
      );
    }
  }, [props.autoSassAppsNames]);

  const LIMIT = 50;

  useEffect(() => {
    fetchUserBooks();
  }, [currentPage, searchedText, sortBy]);

  useEffect(() => {
    const storedUserEmail = localStorage.getItem('USER_EMAIL') || '';
    setUserEmail(storedUserEmail);

    const mediaQuery = window.matchMedia('(max-width: 1000px)');
    if (mediaQuery.matches) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
    fetchUserBooks();

    const userAuth = validateUser();
    if (!userAuth) {
      window.open('/console/login', '_self');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCreateNewBook = () => {
    if (
      props.userDetails.scopes?.includes('BOOKLE_GENERAL_ACCESS') &&
      props.userDetails.aiCredits! <= 0
    ) {
      if (
        props.userDetails.scopes?.includes('BOOKLE_MEDIUM_BOOK') ||
        props.userDetails.scopes?.includes('BOOKLE_LARGE_BOOK')
      ) {
        console.log('not enough ai credits to generate books');
        setUpgradePopupVisible(true);
      } else {
        setFreeCreditsPopupMode(FreeCreditsPopupModes.OutOfCredits);
        setFreeCreditsPopupVisible(true);
      }
    } else {
      const { domain } = getDomain();
      window.open(`//${domain}/edit/book`, '_self');
    }
  };

  const handleCopyUrl = (id: string) => {
    const { domain } = getDomain();
    navigator.clipboard.writeText(`${domain}/edit/bl/${id}`);
  };

  const handleBookDuplicate = async (id: string) => {
    await graphQlCall({
      queryTemplateObject: QUERIES.DUPLICATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: { id },
    });
    fetchUserBooks();
  };

  const handleStartDeleting = (bookId: string) => {
    setEditingBookId(bookId);
    setOpenDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setEditingBookId(null);
    setOpenDeletePopup(false);
  };

  const handleDelete = async (id: string) => {
    setEditingBookId(null);
    setOpenDeletePopup(false);
    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_BOOK,
      headerType: 'USER-AUTH',
      values: { id },
    });
    const newBooks = booksData.filter((book) => book._id !== id);
    setBooksData(newBooks);
  };

  const handleShowPdf = (book: IBook) => {
    const { pdfFile, title } = book;
    if (!pdfFile || !title) {
      return;
    }
    window.open(pdfFile, '_blank');
  };

  const fetchUserBooks = async () => {
    try {
      const skip = (currentPage - 1) * LIMIT;

      const response = await graphQlCall({
        queryTemplateObject: QUERIES.GET_BOOKS_WITH_PAGINATION,
        headerType: 'USER-AUTH',
        values: {
          skip,
          limit: LIMIT,
          search: searchedText,
          sortBy,
          sortAsc: sortBy === 'title',
        },
      });

      const { books, total } = response;

      setBooksData(books);
      setTotalBooks(total);
    } catch (error) {
      console.error(error);
    } finally {
      setBooksLoading(false);
    }
  };

  const handleStartExport = (exportFormat: string, book: IBook) => {
    const bookId = book._id;
    if (exportFormat === 'pdf') {
      if (!book.pdfFile) {
        return;
      }
      setInitialDownloadLink(getLinkWithoutCDN(book.pdfFile));
    }
    setExportFormat(exportFormat);
    setEditingBookId(bookId);
  };

  const handleCloseExportPopup = () => {
    setEditingBookId(null);
    setExportFormat(null);
    setInitialDownloadLink(null);
  };

  const getLinkWithoutCDN = (link: string) => {
    if (link.includes('https://cdn.autofunnel.ai')) {
      return link.replace(
        'https://cdn.autofunnel.ai',
        'https://s3.us-east-2.amazonaws.com/cdn.autofunnel.ai'
      );
    }
    return link;
  };

  const forceDownload = async (fileUrl: string) => {
    fileUrl = getLinkWithoutCDN(fileUrl);
    const filePathElements = fileUrl.split('.');
    const ext = filePathElements[filePathElements.length - 1];
    let fileName = '';
    booksData.forEach((book) => {
      if (book._id === editingBookId) {
        fileName = book?.title?.replaceAll(' ', '_') || 'exported_book';
      }
    });

    fileName += `.${ext}`;

    fetch(fileUrl, {
      method: 'GET',
      cache: 'no-cache',
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      });
  };

  const handlePlanOpen = () => {
    setFreeCreditsPopupVisible(false);
    setUpgradePopupVisible(true);
  };

  const handleSearch = async (text: string) => {
    setCurrentPage(1);
    setSearchedText(text);
  };

  return (
    <div className={s.booksPage}>
      <div
        className={clsx(s.pageBooksNavigationWrapper, {
          [s.moveLeft]: !isSidebarOpen,
        })}
      >
        <SideMenu />
        {props.userDetails.scopes &&
          props.userDetails.scopes.includes('BOOKLE_GENERAL_ACCESS') && (
            <Credits
              isSidebarOpen={isSidebarOpen}
              aiCredits={props.userDetails.aiCredits}
              onFreeCreditsClick={() => setUpgradePopupVisible(true)}
              onUpgradePlanClick={() => {
                setFreeCreditsPopupVisible(true);
                setFreeCreditsPopupMode(FreeCreditsPopupModes.Default);
              }}
            />
          )}
        <div
          className={clsx(s.sidebarToggleBtnBlock, {
            [s.sidebarOpen]: isSidebarOpen,
          })}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img src={burgerSvg} alt="burger svg" />
        </div>
      </div>
      <div className={isSidebarOpen ? s.booksContentRight : s.booksContent}>
        {booksLoading ? (
          <main className={s.loadingCards}>
            <BooksSkeleton />
          </main>
        ) : (
          <main className={s.cards}>
            <DashboardHeader
              title={`Your Books`}
              handleSearch={handleSearch}
              handleAddNewItem={handleCreateNewBook}
              buttonText={`New Book`}
              total={`${totalBooks} Books`}
              sortOptions={sortOptions}
              handleSort={(item: string) => setSortBy(item)}
              sortBy={sortBy}
            />
            <div className={s.booksContainer}>
              {booksData.map((book) => (
                <BookCard
                  book={book}
                  key={book._id}
                  onSettingsClick={() =>
                    props.triggerSettingsPopup({ open: true })
                  }
                  onDuplicate={() => handleBookDuplicate(book._id)}
                  onCopyUrl={() => handleCopyUrl(book._id)}
                  onDelete={() => handleStartDeleting(book._id)}
                  onStartExport={(exportFormat) =>
                    handleStartExport(exportFormat, book)
                  }
                  onShowPdf={() => handleShowPdf(book)}
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalBooks / LIMIT)}
              onPageChange={(page) => setCurrentPage(page)}
            />
            {editingBookId && exportFormat && (
              <ExportBookPopup
                bookId={editingBookId}
                exportFormat={exportFormat}
                onClose={handleCloseExportPopup}
                onComplete={forceDownload}
                initialLink={initialDownloadLink}
              />
            )}
            <ConfirmArchivationPopup
              onClose={handleCloseDeletePopup}
              onDonePressed={() => handleDelete(editingBookId || '')}
              open={!!(openDeletePopup && editingBookId)}
              description="Are you sure that you want to delete this book?It will no longer be available to you. All data will be lost!"
            />
            <SettingsPopup />
            <UpdatePlanPopup
              open={upgradePopupVisible}
              onClose={() => setUpgradePopupVisible(false)}
            />
            {freeCreditsPopupVisible && (
              <FreeCreditsPopup
                invitationLink={`https://signup.bookle.ai?rid=${props.userDetails.owner}`}
                credits={props.userDetails.aiCredits}
                mode={freeCreditsPopupMode}
                onPlanOpen={handlePlanOpen}
                onClose={() => setFreeCreditsPopupVisible(false)}
              />
            )}
          </main>
        )}
        {props.userDetails.aiCredits! <= 0 &&
        // !isSidebarOpen &&
        props.userDetails.scopes?.includes('BOOKLE_GENERAL_ACCESS') &&
        (props.userDetails.scopes?.includes('BOOKLE_MEDIUM_BOOK') === false ||
          props.userDetails.scopes?.includes('BOOKLE_LARGE_BOOK') === false) ? (
          <FreeBookBanner
            isSidebarOpen={isSidebarOpen}
            onOpen={() => {
              setFreeCreditsPopupVisible(true);
              setFreeCreditsPopupMode(FreeCreditsPopupModes.FreeLargeBook);
            }}
          />
        ) : (
          ''
        )}
      </div>
      <StartVideoBlock
        src="https://cdn.autofunnel.ai/Bookle+Walkthrough.mp4"
        name="bookleConsoleVideoPlayed"
        redirectUrl="/edit/book"
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
  autoSassAppsNames: state.autosaas.appsNames,
});

const mapDispatchToProps = {
  triggerSettingsPopup: (payload: {
    open?: boolean;
    initialState?: IUserProduct;
  }) => bookSettingsPopupAction(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(PageBooks);
