export const UPDATE_SAAS_DESIGN = {
  operationName: 'updateSaasDesign',
  query: `mutation updateSaasDesign ($colorScheme: String, $alerts: String, $apps: String, $sideBar: String, $accent: String, $name: String, $helpCenterUrl: String) {
            updateSaasDesign (colorScheme: $colorScheme, alerts: $alerts, apps: $apps, sideBar: $sideBar, accent: $accent, name: $name, helpCenterUrl: $helpCenterUrl) {
              colorScheme
              domain
              logoUrl
              name
              helpCenterUrl
              alerts {
                success
                successHover
                error
                waiting
              }
              sideBar {
                background
                appHover
                appSelected
                divider
                text
                selectedText
              }
              accent {
                accent
                accentHover
                darkAccent
                darkAccentHover
              }
            }
          }`,
};
