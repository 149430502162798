import { useRef } from 'react';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import clsx from 'clsx';
import Dropdown from 'UILib/Dropdown/Dropdown';
import DropdownLabel from './DropdownLabel/DropdownLabel';
import ColorSelectionBox from './ColorSelectionBox/ColorSelectionBox';

import styles from './SettingsModal.module.scss';

interface IProps {
  styles: IBookleTemplateBlockStyles;
  updateStyles: (actions: IBookleTemplateBlockStyles) => void;
  open: boolean;
  handleClose: () => void;
}

const mockOptions = [
  { label: <DropdownLabel value={'400px'} />, value: 400 },
  { label: <DropdownLabel value={'450px'} />, value: 450 },
  { label: <DropdownLabel value={'500px'} />, value: 500 },
  { label: <DropdownLabel value={'550px'} />, value: 550 },
  {
    label: <DropdownLabel value={'600px'} />,
    value: 600,
  },
  { label: <DropdownLabel value={'650px'} />, value: 650 },
  { label: <DropdownLabel value={'700px'} />, value: 700 },
  { label: <DropdownLabel value={'750px'} />, value: 750 },
  { label: <DropdownLabel value={'800px'} />, value: 800 },
];

const SettingsModal = (props: IProps) => {
  const settingsModalRef = useRef<HTMLDivElement | null>(null);

  UseOnClickOutside(settingsModalRef, (event) => {
    const targetElement = event.target as HTMLElement;

    if (
      props.open &&
      !Array.from(targetElement.classList).some((className) =>
        className.includes('Dropdown')
      )
    ) {
      props.handleClose();
    }
  });

  return (
    <div
      className={clsx(styles.container, { [styles.open]: props.open })}
      ref={settingsModalRef}
    >
      <div className={styles.settingsSectionContainer}>
        <div className={styles.sectionLabel}>Background:</div>
        <div className={styles.settingsDropdowns}>
          <ColorSelectionBox
            label="Background Color"
            value={props.styles.emailBg || '#fff'}
            onChange={(color) =>
              props.updateStyles({ ...props.styles, emailBg: color })
            }
          />
        </div>
        <div className={styles.sectionLabel}>Body:</div>
        <div className={styles.settingsDropdowns}>
          <Dropdown
            type="stroke"
            label={<DropdownLabel value={'600px'} />}
            onChange={(newValue) =>
              props.updateStyles({ ...props.styles, bodyWidth: newValue })
            }
            value={props.styles.bodyWidth}
            theme="dark"
            options={mockOptions}
          />
          <ColorSelectionBox
            label="Body Background"
            value={props.styles.bodyColor || '#fff'}
            onChange={(color) =>
              props.updateStyles({ ...props.styles, bodyColor: color })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
