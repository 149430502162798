import clsx from 'clsx';
import SkeletonItem from '../SkeletonItem/SkeletonItem';

import styles from './ContactsSkeleton.module.scss';

interface IProps {
  className?: string;
}

const ContactsSkeleton = ({ className }: IProps) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.line}>
          <SkeletonItem className={styles.pageName} />
          <SkeletonItem className={styles.addButton} />
        </div>
        <div className={styles.line}>
          <SkeletonItem className={styles.addButton} />
          <SkeletonItem className={styles.sort} />
        </div>
      </div>
      <div className={styles.body}>
        {Array(30)
          .fill(null)
          .map((_, index) => {
            return <SkeletonItem className={styles.item} key={index} />;
          })}
      </div>
    </div>
  );
};

export default ContactsSkeleton;
