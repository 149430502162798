import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import {
  BlocksHistory,
  defaultTemplateStyles,
  IBookleTemplateBlockStyles,
  IBookleTemplateEditor,
} from 'store/books/booksReducer';
import {
  clearBlocksHistory,
  redoBlocks,
  undoBlocks,
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { getToken, api } from 'utils/Utils';
import { capitalizeFirstLetter, base64ToFile } from 'utils/helpers';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { PAGECRAFT_API_URL } from '../../Constants';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import UndoRedoButtons from 'Components/UndoRedoButtons/UndoRedoButtons';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import GenerationPreview from './GenerationPreview/GenerationPreview';

import styles from './PageGenerationEditor.module.scss';
import { getLayoutThumbnail } from '../../utils/Utils';

interface IProps {
  blocks: BookleTemplateBlock[];
  templateTextEditor: IBookleTemplateEditor;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
  blocksHistory: BlocksHistory;
  redoBlocks: () => void;
  undoBlocks: () => void;
  clearBlocksHistory: () => void;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
  templateBlockStyles: IBookleTemplateBlockStyles;
}

const PageGenerationTaskEditor = ({
  blocks,
  templateTextEditor,
  updateBlocks,
  updateTextEditor,
  blocksHistory,
  redoBlocks,
  undoBlocks,
  clearBlocksHistory,
  updateBlockStyles,
  templateBlockStyles,
}: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appId = queryParams.get('app');
  const [app, setApp] = useState<IAutoApp | undefined>(undefined);

  const { taskId } = useParams<{ taskId: string; project?: string }>();

  const [generationTask, setGenerationTask] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    updateBlockStyles(defaultTemplateStyles);
    loadGenerationTaskData();
    return () => {
      updateBlocks([]);
      clearBlocksHistory();
    };
  }, []);

  const undoRedoButtons = useMemo(() => {
    return (
      <UndoRedoButtons
        undo={undoBlocks}
        redo={redoBlocks}
        disabledUndo={blocksHistory.activeIndex === 0}
        disabledRedo={
          !blocksHistory.blocks.length ||
          blocksHistory.blocks.length - 1 === blocksHistory.activeIndex
        }
      />
    );
  }, [blocksHistory, redoBlocks, undoBlocks]);

  const ref = useRef<HTMLDivElement>(null);
  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as any));

    if (
      ref.current &&
      !ref.current.contains(e.target as any) &&
      !clickedInsideTextEditor
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });
  useEffect(() => {
    if (appId) {
      graphQlCall({
        queryTemplateObject: queries.GET_ONE_AUTO_APP,
        headerType: 'USER-AUTH',
        values: {
          id: appId,
        },
      }).then((data) => {
        setApp(data);
      });
    }
  }, [appId]);

  const handleRedirectToTemplates = () => {
    history.push(
      app?._id
        ? `/console/app/${app.name.toLowerCase().replace(/\s+/g, '')}/${
            app?._id
          }`
        : `/console/outreach/templates`
    );
  };

  const handleTaskSave = async () => {
    console.log('SAVING....');

    let valuesToSave: any = {
      id: taskId,
      name: generationTask.name,
      status: 'MERGED',
    };

    if (blocks.length > 0) {
      valuesToSave['layout'] = JSON.stringify({
        blocks,
        templateBlockStyles: templateBlockStyles,
      });
    }

    await graphQlCall({
      queryTemplateObject: queries.UPDATE_GENERATION_TASK_MUTATION,
      values: valuesToSave,
      headerType: 'USER-AUTH',
    });

    //saving image thumbnail
    if (blocks) {
      const dataUrl = await getLayoutThumbnail();
      const data = new FormData();
      data.append('id', taskId);
      data.append('type', 'task');
      data.append('file', base64ToFile(dataUrl, taskId));
      await api(
        `${PAGECRAFT_API_URL}/generation/upload-thumbnail`,
        'POST',
        data,
        {
          Authorization: getToken(),
        }
      );
    }
    console.log('SAVE is complete');
  };

  const loadGenerationTaskData = () => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TASK,
      values: { id: taskId },
      headerType: 'USER-AUTH',
    })
      .then((data) => {
        setGenerationTask(data);
        setLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleTaskNameChange = (name: string) => {
    generationTask.name = name;
    setGenerationTask({ ...generationTask });
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div ref={ref} id="header">
        <EditHeader
          showConfirmButton={true}
          pageName={generationTask?.name || ''}
          editableTitle
          onTitleChange={handleTaskNameChange}
          title={`${!!appId ? 'Project' : 'Template'} name`}
          handleConfirm={handleTaskSave}
          handleGoBack={handleRedirectToTemplates}
          buttonPlaceholder="Save"
          additionalButtons={
            <div className={styles.customHeader}>{undoRedoButtons}</div>
          }
          customHeaderPlace="right"
          showCustomHeader={!!templateTextEditor.editor}
          className={styles.header}
          customHeader={
            <div className={styles.customHeader}>
              {undoRedoButtons}
              <TextEditorToolbar
                editor={templateTextEditor.editor}
                selection={templateTextEditor.selection}
                showListItems={false}
              />
            </div>
          }
        />
      </div>
      <div className={styles.workbench}>
        <div className={styles.generationPreview}>
          <GenerationPreview generationTaskId={taskId} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
  blocks: state.books.bookleTemplateBlocks,
  blocksHistory: state.books.blocksHistory,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
  undoBlocks: () => undoBlocks(),
  redoBlocks: () => redoBlocks(),
  clearBlocksHistory: () => clearBlocksHistory(),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageGenerationTaskEditor);
