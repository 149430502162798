import Input from 'UILib/Input/Input';
import styles from './AddNewAutoFile.module.scss';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TextArea from 'UILib/TextArea/TextArea';

export const getFormElement = (
  form: string,
  element: {
    id: string;
    type: string;
    label: string;
    options?: string[];
  },
  generationData: Record<string, Record<string, string | number>>,
  formValidationError: boolean,
  setGenerationData: (
    item: Record<string, Record<string, string | number>>
  ) => void
) => {
  const formData = generationData[form] ?? {};

  switch (element.type) {
    case 'Input':
      return (
        <Input
          border="stroke"
          className={styles.input}
          error={
            formValidationError && !formData[element.id]
              ? 'Value required'
              : undefined
          }
          value={formData[element.id]}
          onChange={(e) => {
            generationData[form][element.id] = e.target.value;
            setGenerationData(Object.assign({}, generationData));
          }}
        />
      );
    case 'Dropdown':
      return (
        <Dropdown
          type="stroke"
          label="Select"
          options={
            element.options?.map((option) => ({
              label: option,
              value: option,
            })) ?? []
          }
          value={formData[element.id]}
          hasErrors={formValidationError && !formData[element.id]}
          onChange={(newValue) => {
            generationData[form][element.id] = newValue;
            setGenerationData(Object.assign({}, generationData));
          }}
        />
      );
    case 'Textarea':
      return (
        <TextArea
          height={230}
          hasErrors={formValidationError && !formData[element.id]}
          value={formData[element.id]}
          onChange={(newValue) => {
            generationData[form][element.id] = newValue;
            setGenerationData(Object.assign({}, generationData));
          }}
        />
      );
    default:
      return null;
  }
};
