import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { ENVIRONMENT } from 'Constants';
import { ReactComponent as AddPageImg } from 'Assets/icons/addPage.svg';
import { ReactComponent as BackArrowSvg } from 'Assets/icons/backArrow.svg';
import { ReactComponent as ArchiveImage } from 'Assets/icons/Dashboard Page/archiveIcon.svg';
import { ReactComponent as SettingsImage } from 'Assets/icons/settingsIconsWhite.svg';
import { ReactComponent as RightArrowImage } from 'Assets/icons/rightArrowStats.svg';
import { getActiveSubId, getUserName } from 'utils/Utils';
import { FunnelTotalStats } from 'services/types';
import { toLocalISOString } from 'utils/helpers';
import {
  setProjectName,
  funnelDuplicateAction,
  duplicatePageOtherAccountAction,
  duplicateFunnelOtherAccountAction,
} from 'store/projects/projectsActions';
import {
  setDomainAddingStatus,
  removeFromProject,
  NOT_AT_ALL_STATUS,
  NEUTRAL_STATUS,
  WOO_HOO_STATUS,
  fetchDomains,
  addDomain,
} from 'store/domains/domainsActions';
import {
  IDomain,
  IFunnel,
  IGeneralProject,
  IDuplicatePageOtherAccountPayload,
  IDuplicateFunnelOtherAccountPayload,
} from 'types';
import Link from 'UILib/Link/Link';
import urlIcon from 'Assets/icons/urlIcon.svg';
import pagesImg from 'Assets/icons/pages.svg';
import statsViews from 'Assets/icons/statsViews.svg';
import statsLeads from 'Assets/icons/statsLeads.svg';
import statsSales from 'Assets/icons/statsSales.svg';
import domainIcon from 'Assets/icons/domainIcon.svg';
import statsDecor from 'Assets/icons/statsDecor.svg';
import PageStatistics from 'Pages/PageStatistics/PageStatistics';
import AnalyticsService from 'services/analytics.service';
import SecondaryButton from '../../Common/SecondaryButton/SecondaryButton';
import PrimaryButton from '../../Common/PrimaryButton/PrimaryButton';
import ContentEditableDiv from '../../Common/ContentEditableDiv/ContentEditableDiv';
import AllDomainsPopup from '../../Domains/AllDomainsPopup';
import ProjectLanding from '../ProjectSettings/PageProjectsLanding/PageProjectsLanding';
import NotAtAllPopup from '../../Domains/NotAtAllPopup';
import WooHooPopup from '../../Domains/WooHooPopup';
import Spinner from '../../Common/Spinner/Spinner';
import DatePicker from '../../Common/DatePicker/DatePicker';
import ConfirmArchivationPopup from '../../Domains/ConfirmArchivationPopup';
import DuplicatePageOtherAccountPopup from '../popups/DuplicatePageOtherAccountPopup/DuplicatePageOtherAccountPopup';
import DuplicateFunnelOtherAccountPopup from '../popups/DuplicateFunnelOtherAccountPopup/DuplicateFunnelOtherAccountPopup';
import { IAccount } from '../../Accounts/types';

import s from './ProjectContent.module.scss';

interface IProps {
  project: IGeneralProject;
  onAddProductClicked: (clicked: boolean) => void;
  onDuplicate: (id: string, funnelId?: string) => void;
  onSettingsClicked: () => void;
  onOpenPageSettings: (page: IFunnel) => void;
  onConfirmArchive: () => void;
  onDomainSetupPopup: () => void;
  onNameEdited: (value: string, pageId?: string, pageEnabled?: boolean) => void;
  onClose: () => void;
  showDomainSetup: boolean;
  domains: IDomain[];
  fetchDomains: () => void;
  addDomain: (name: string, projectId?: string) => void;
  addingDomainStatus: string;
  setDomainAddingStatus: (status: string) => void;
  removeFromProject: (id: string) => void;
  setProjectNameAction: (id: string, name: string) => void;
  funnelDuplicate: (id: string) => void;
  loadingProjectDuplicate: boolean;
  subAccounts: IAccount[];
  activeSubAccount: IAccount | null;
  duplicatePageOtherAccountAction: (
    payload: IDuplicatePageOtherAccountPayload
  ) => void;
  duplicateFunnelOtherAccountAction: (
    payload: IDuplicateFunnelOtherAccountPayload
  ) => void;
}

const ProjectContent = ({
  project,
  onAddProductClicked,
  onDuplicate,
  onSettingsClicked,
  onOpenPageSettings,
  onConfirmArchive,
  onDomainSetupPopup,
  onNameEdited,
  onClose,
  showDomainSetup,
  domains,
  fetchDomains,
  addDomain,
  addingDomainStatus,
  setDomainAddingStatus,
  removeFromProject,
  setProjectNameAction,
  funnelDuplicate,
  loadingProjectDuplicate,
  subAccounts,
  activeSubAccount,
  duplicatePageOtherAccountAction,
  duplicateFunnelOtherAccountAction,
}: IProps) => {
  const [projectId, setProjectId] = useState<string | undefined>();
  const [projectName, setProjectName] = useState('');
  const [pageSelectId, setPageSelectId] = useState<string | null>(null);
  const [allDomainsPopupIsOpen, setAllDomainsPopupIsOpen] = useState(false);
  const [archiveFunnelPopupIsOpen, setArchiveFunnelPopupIsOpen] = useState(
    false
  );
  const [statisticPeriodDate, setStatisticPeriodDate] = useState<{
    from: string;
    to: string;
  } | null>(null);

  const [totalStats, setTotalStats] = useState<FunnelTotalStats>();
  const [pagesStatistics, setPagesStatistics] = useState<
    Record<string, FunnelTotalStats>
  >();

  const [connectedDomain, setConnectedDomain] = useState<IDomain | undefined>();
  const [
    openDuplicateFunnelAccountPopup,
    setOpenDuplicateFunnelAccountPopup,
  ] = useState(false);
  const [
    openDuplicatePageAccountPopup,
    setOpenDuplicatePageAccountPopup,
  ] = useState(false);
  const [pageId, setPageId] = useState('');
  const showTemplateSelector = useRef(false);

  const params: any = useParams();
  const history: any = useHistory();

  let sub = getUserName();
  const subUserId = getActiveSubId();
  if (subUserId) {
    const account = subAccounts.find((item) => item._id === subUserId);
    if (account) {
      sub = account.owner;
    }
  }

  useEffect(() => {
    setProjectName(project.name);
    searchAndSetConnectedDomain();
  }, [project]);

  useEffect(() => {
    if (!project || !statisticPeriodDate) return;

    const analyticsService = new AnalyticsService();

    analyticsService
      .getTotalStats({
        funnel: project._id,
        time_ranges: [
          {
            from_time: statisticPeriodDate?.from,
            to_time: statisticPeriodDate?.to,
          },
        ],
      })
      .then((response) => {
        if (!response) return;

        const data = Object.values(response)[0];
        const { total_stats, ...rest } = data;

        setTotalStats(total_stats);
        setPagesStatistics(rest);
      });
  }, [project, statisticPeriodDate]);

  const handleDateChange = (start: any, end: any) => {
    setStatisticPeriodDate({
      from: toLocalISOString(start),
      to: toLocalISOString(end),
    });
  };

  useEffect(() => {
    if (params.pageId) {
      setPageSelectId(params.pageId);
    } else {
      setPageSelectId(null);
    }
  }, [params.pageId]);

  useEffect(() => {
    if (project.pages.length === 0) {
      onAddProductClicked(true);
    }
    fetchDomains();
    if (projectId !== project._id) {
      setProjectId(project._id);
    }
    searchAndSetConnectedDomain();
  }, []);

  useEffect(() => {
    searchAndSetConnectedDomain();
  }, [domains]);

  useEffect(() => {
    if (project.pages.length === 0 && !showTemplateSelector.current) {
      onAddProductClicked(true);
      showTemplateSelector.current = true;
    } else if (project.pages.length > 0 && showTemplateSelector.current) {
      showTemplateSelector.current = false;
    }
  }, [project.pages, onAddProductClicked]);

  const searchAndSetConnectedDomain = () => {
    setConnectedDomain(undefined);
    domains.forEach((domain) => {
      if (domain.projectId === project._id) {
        setConnectedDomain(domain);
      }
    });
  };

  const generateProjectUrl = (project: IGeneralProject) => {
    let rootDomain = 'autofunnel.ai';
    if (ENVIRONMENT === 'DEV') {
      rootDomain = 'dev.autofunnel.ai';
    }
    if (connectedDomain) {
      return `https://${connectedDomain.name}`;
    }

    if (project.customSubDomain) {
      return `https://${project.customSubDomain}.${rootDomain}`;
    }

    return `https://live.${rootDomain}/${project._id}`;
  };

  let pages = project.pages.filter((item) => item.archived === false);

  const handleAddDomain = (domainName: string) => {
    addDomain(domainName, project._id);
  };

  const handleClosePopup = () => {
    setDomainAddingStatus(NEUTRAL_STATUS);
  };

  const handleRemoveFromProject = () => {
    if (!connectedDomain) {
      return;
    }
    removeFromProject(connectedDomain._id);
  };

  const handleChangeProjectName = (name: string) => {
    setProjectName(name);
    setProjectNameAction(project._id, name);
  };
  const handleAllStatsClick = () => {
    history.push(`/console/projects/${project._id}/page/all`);
  };

  const handleDuplicateFunnel = (funnelId: string) => {
    if (subAccounts.length) {
      setOpenDuplicateFunnelAccountPopup(true);
    } else {
      funnelDuplicate(funnelId);
    }
  };

  const handleStartDuplicatePageOtherAccount = (id: string) => {
    setPageId(id);
    setOpenDuplicatePageAccountPopup(true);
  };

  const getDifferencePercentClass = (oldValue: number, newValue: number) => {
    if (oldValue === 0 || oldValue === newValue || newValue === 0) {
      return s.differencePercent;
    }
    if (oldValue < newValue) {
      return `${s.differencePercent} ${s.differencePercentGreen}`;
    }
    if (oldValue > newValue) {
      return `${s.differencePercent} ${s.differencePercentRed}`;
    }
  };
  function calculatePercentageChange(
    oldValue: number,
    newValue: number
  ): string {
    const difference = newValue - oldValue;
    const percentageChange = (difference / oldValue) * 100;
    if (oldValue === 0 || newValue === 0) {
      return 'Infinity';
    }

    if (percentageChange > 0) {
      return `+${Math.round(percentageChange)}%`;
    } else if (percentageChange < 0) {
      return `${Math.round(percentageChange)}%`;
    } else {
      return '0%';
    }
  }

  const mainAccount = {
    _id: '',
    email: '',
    name: 'Main Account',
    owner: getUserName(),
  };

  const accountsToCopy = [mainAccount];

  if (subAccounts.length) {
    accountsToCopy.push(...subAccounts);
  }

  if (pageSelectId) {
    return (
      <div className={s.container}>
        <PageStatistics
          projectName={projectName}
          projectId={projectId}
          pages={project.pages.filter((page) => !page.archived)}
          pageOrder={project.order}
          pageId={pageSelectId}
        />
      </div>
    );
  } else
    return (
      <div className={s.container}>
        <Link
          to="/console/projects"
          className={s.backLink}
          prefixIcon={<BackArrowSvg />}
        >
          Back to Funnels
        </Link>
        <div className={s.funnel}>
          <div className={s.funnelTop}>
            <div className={s.funnelTopLeft}>
              <ContentEditableDiv
                name={projectName}
                fontSize={30}
                lineHeight={40}
                fontWeight={400}
                height={45}
                onChange={handleChangeProjectName}
                onEdit={onNameEdited}
              />
            </div>
          </div>
          <div className={s.domainBlock}>
            <div className={s.funnelInfo}>
              <div className={s.funnelInfoLeft}>
                <img className={s.icon} src={domainIcon} />
                <p className={s.infoName} style={{ minWidth: 'auto' }}>
                  Domain
                </p>
                {connectedDomain ? (
                  <>
                    <span style={{ left: '0' }}>
                      <b>{connectedDomain.name}</b>
                    </span>
                    <div className={s.disconnectBtn}>
                      <PrimaryButton
                        height={30}
                        width={120}
                        borderRadius={10}
                        onClick={() => handleRemoveFromProject()}
                        isBlackBtn={false}
                      >
                        Disconnect
                      </PrimaryButton>
                    </div>
                  </>
                ) : (
                  <PrimaryButton
                    height={30}
                    width={100}
                    borderRadius={10}
                    onClick={() => setAllDomainsPopupIsOpen(true)}
                    isBlackBtn={false}
                  >
                    Connect
                  </PrimaryButton>
                )}
                <div className={s.funnelInfoUrl}>
                  <img className={s.icon} src={urlIcon} />
                  <p className={s.infoName}>Public URL:</p>
                  <a
                    className={s.infoDataLink}
                    href={generateProjectUrl(project)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {generateProjectUrl(project)}
                  </a>
                </div>
              </div>
              <div>
                <div className={s.buttonContainer}>
                  <SecondaryButton
                    height={50}
                    width={200}
                    postfixImage={<SettingsImage />}
                    onClick={onSettingsClicked}
                  >
                    Funnel Settings
                  </SecondaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.statsBlock}>
          <div className={s.statsBlockTop}>
            <div className={s.statsBlockTopLeft}>
              <div className={s.statsName}>
                <img src={statsDecor} alt="stats" className={s.statsDecor} />
                Stats
              </div>
              <DatePicker
                startDate={null}
                endDate={null}
                onChange={handleDateChange}
                isSmall={false}
                startRange={'All Time'}
              />
            </div>
            <div className={s.buttonContainer}>
              <SecondaryButton
                height={50}
                width={200}
                postfixImage={<RightArrowImage width={16} height={16} />}
                onClick={handleAllStatsClick}
              >
                More Stats
              </SecondaryButton>
            </div>
          </div>
          <div className={s.statsBlockBottom}>
            {totalStats && (
              <>
                <div className={s.statsBlockItem}>
                  <div className={s.statsBlockItemQuantity}>
                    <img src={statsViews} alt="views" />
                    {totalStats.total_visitors}
                    {/* <span
                      className={getDifferencePercentClass(
                        viewsQuantity?.previous,
                        viewsQuantity?.current
                      )}
                    >
                      {calculatePercentageChange(
                        viewsQuantity?.previous,
                        viewsQuantity?.current
                      )}
                    </span> */}
                  </div>
                  <div className={s.statsLabel}>Total Unique Views</div>
                </div>
                <div className={s.statsBlockItem}>
                  <div className={s.statsBlockItemQuantity}>
                    <img src={statsLeads} alt="leads" />
                    {totalStats.total_leads}
                    {/* <span
                      className={getDifferencePercentClass(
                        leadsQuantity?.previous,
                        leadsQuantity?.current
                      )}
                    >
                      {calculatePercentageChange(
                        leadsQuantity?.previous,
                        leadsQuantity?.current
                      )}
                    </span> */}
                  </div>
                  <div className={s.statsLabel}>Total Leads</div>
                </div>
                <div className={s.statsBlockItem}>
                  <div className={s.statsBlockItemQuantity}>
                    <img src={statsSales} alt="sales" />
                    {totalStats.total_sales}
                    {/* <span
                      className={getDifferencePercentClass(
                        salesQuantity?.previous,
                        salesQuantity?.current
                      )}
                    >
                      {calculatePercentageChange(
                        salesQuantity?.previous,
                        salesQuantity?.current
                      )}
                    </span> */}
                  </div>
                  <div className={s.statsLabel}>Total Sales</div>
                </div>
              </>
            )}
          </div>
        </div>

        {pages.length > 0 ? (
          <div>
            <div className={s.pagesHeader}>
              <div className={s.pagesHeaderLeft}>
                <img src={pagesImg} alt="pages" className={s.pagesImg} />
                Pages
              </div>
              <div className={s.buttonContainer}>
                <PrimaryButton
                  height={50}
                  width={200}
                  borderRadius={10}
                  onClick={() => onAddProductClicked(false)}
                  isBlackBtn={false}
                  prefixImage={<AddPageImg fill="white" />}
                >
                  Add Page
                </PrimaryButton>
              </div>
            </div>
            <div>
              <ProjectLanding
                project={project}
                connectedDomain={connectedDomain}
                onDuplicate={(pageId: string, funnelId?: string) =>
                  onDuplicate(pageId, funnelId)
                }
                pagesStatistics={pagesStatistics}
                onOpenPageSettings={(page: IFunnel) => onOpenPageSettings(page)}
                isAgency={subAccounts.length > 0}
                duplicatePageToAnotherAccount={(pageId) =>
                  handleStartDuplicatePageOtherAccount(pageId)
                }
              />
            </div>
            <div className={s.archiveAndDuplicateButtonsBlock}>
              <div className={s.archieveFunnelButton}>
                <SecondaryButton
                  prefixImage={<ArchiveImage />}
                  onClick={() => setArchiveFunnelPopupIsOpen(true)}
                >
                  Delete Funnel
                </SecondaryButton>
              </div>
              {loadingProjectDuplicate ? (
                <div className={s.loadingProjectDuplicateSpinner}>
                  <Spinner size={30} />
                </div>
              ) : (
                <div className={s.duplicateFunnelButton}>
                  <SecondaryButton
                    onClick={() => handleDuplicateFunnel(project._id)}
                  >
                    Duplicate Funnel
                  </SecondaryButton>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={s.archieveFunnelButton}>
            <SecondaryButton
              prefixImage={<ArchiveImage />}
              onClick={() => setArchiveFunnelPopupIsOpen(true)}
            >
              Delete Funnel
            </SecondaryButton>
          </div>
        )}

        <NotAtAllPopup
          onClose={() => handleClosePopup()}
          open={addingDomainStatus === NOT_AT_ALL_STATUS}
        />
        <WooHooPopup
          onClose={() => handleClosePopup()}
          open={addingDomainStatus === WOO_HOO_STATUS}
          hasFunnel={!!connectedDomain}
          funnels={[]}
          domainName={connectedDomain?.name || ''}
          onAssociate={() => {}}
        />
        <AllDomainsPopup
          open={allDomainsPopupIsOpen}
          onClose={() => {
            setAllDomainsPopupIsOpen(false);
          }}
        />
        <ConfirmArchivationPopup
          open={archiveFunnelPopupIsOpen}
          description="Once you delete your funnel, all the pages and data from it will be gone. Are you sure you want to continue?"
          onDonePressed={() => {
            onConfirmArchive();
            setArchiveFunnelPopupIsOpen(false);
          }}
          onClose={() => setArchiveFunnelPopupIsOpen(false)}
        />
        <DuplicateFunnelOtherAccountPopup
          funnelId={project._id}
          owner={sub}
          open={openDuplicateFunnelAccountPopup}
          accounts={accountsToCopy}
          onClose={() => setOpenDuplicateFunnelAccountPopup(false)}
          onDuplicate={duplicateFunnelOtherAccountAction}
        />
        <DuplicatePageOtherAccountPopup
          pageId={pageId}
          owner={sub}
          open={openDuplicatePageAccountPopup}
          accounts={accountsToCopy}
          onClose={() => setOpenDuplicatePageAccountPopup(false)}
          onDuplicate={duplicatePageOtherAccountAction}
        />
      </div>
    );
};

const mapStateToProps = (state: RootState) => ({
  domains: state.domains.items,
  addingDomainStatus: state.domains.status,
  loadingProjectDuplicate: state.projects.loadingProjectDuplicateData,
  subAccounts: state.accounts.accounts,
  activeSubAccount: state.accounts.activeAccount,
});

const mapDispatchToProps = {
  fetchDomains: () => fetchDomains(),
  addDomain: (name: string, projectId?: string) => addDomain(name, projectId),
  setDomainAddingStatus: (status: string) => setDomainAddingStatus(status),
  removeFromProject: (id: string) => removeFromProject(id),
  funnelDuplicate: (id: string) => funnelDuplicateAction(id),
  setProjectNameAction: (id: string, newName: string) =>
    setProjectName(id, newName),
  duplicatePageOtherAccountAction: (
    payload: IDuplicatePageOtherAccountPayload
  ) => duplicatePageOtherAccountAction(payload),
  duplicateFunnelOtherAccountAction: (
    payload: IDuplicateFunnelOtherAccountPayload
  ) => duplicateFunnelOtherAccountAction(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContent);
