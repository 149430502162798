import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { RootState } from 'store/rootReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { ReactComponent as Arrow } from 'Assets/icons/arrowLeft.svg';
import { graphQlCall } from 'graphql/utils';
import { BookleTemplateBlock } from 'types';
import { createSocket } from 'utils/Utils';
import { updateItem } from '../../utils';
import TextArea from 'UILib/TextArea/TextArea';
import Button from 'UILib/Button/Button';
import queries from 'graphql/queries';

import styles from './GenerateAiImage.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  isReloadAiImage: boolean;
  setIsReloadAiImage: (value: boolean) => void;
  handleCloseModal: () => void;
}

const GenerateAiImage = ({
  item,
  templateBlocks,
  updateBlocks,
  isReloadAiImage,
  setIsReloadAiImage,
  handleCloseModal,
}: IProps) => {
  const [prompt, setPrompt] = useState<string>(item.imagePrompt || '');
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(item.isLoading || false);
  const [aiRequestId, setAiRequestId] = useState<string>('');

  const percentRef = useRef<number>(0);
  const intervalId = useRef<NodeJS.Timeout | number | null>(null);

  const updateBlockItem = (key: string, value: string | boolean) => {
    updateItem(key, value, templateBlocks, updateBlocks, item);
  };

  useEffect(() => {
    if (isReloadAiImage) {
      generateImage();
    }
  }, [isReloadAiImage]);

  const imitateProgressBar = () => {
    percentRef.current = 0;
    setLoading(true);
    setProgress(0);
    updateBlockItem('isLoading', true);
    intervalId.current = setInterval(() => {
      if (percentRef.current < 100) {
        percentRef.current += 1;
        setProgress(percentRef.current);
      }
    }, 250);
  };

  const handleClearInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    updateBlockItem('isLoading', false);
    setLoading(false);
    setProgress(0);
  };

  const generateImage = () => {
    const socket = createSocket();
    imitateProgressBar();
    updateBlockItem('imagePrompt', prompt);
    socket.emit('generate-openai-image', {
      text: prompt,
      imageCount: 4,
      ratio: '1:1',
    });

    socket.on('open-ai-image-generated', (data) => {
      if (data.error) {
        notification.error({
          message: data.error,
          placement: 'topRight',
          duration: 6,
        });
        handleClearInterval();
        setIsReloadAiImage(false);
      }
      if (data.id) {
        setAiRequestId(data.id);
      }
      if (data.imageUrls?.length) {
        updateBlockItem('AIImages', data.imageUrls);
        updateBlockItem('imagePreview', data.imageUrls[0]);
        handleClearInterval();
        setIsReloadAiImage(false);
        handleCloseModal();
      }
    });
  };

  const handleCancel = async () => {
    handleClearInterval();
    if (aiRequestId) {
      await graphQlCall({
        queryTemplateObject: queries.CANCEL_IMAGE_GENERATION,
        headerType: 'USER-AUTH',
        values: {
          id: aiRequestId,
        },
      });
    }
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingBar}>
          <div className={styles.progressBar}>
            <div
              className={styles.progressPercent}
              style={{ width: `${progress}%` }}
            />
          </div>
          <Button width={80} height={30} onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <>
          <TextArea
            value={prompt}
            onChange={(text) => setPrompt(text)}
            className={styles.textArea}
            wrapperClassName={styles.textContainer}
            theme="dark"
            placeholder="Prompt..."
          />
          <div className={styles.buttonContainer}>
            <Button
              width={40}
              height={40}
              appearance="solid"
              onClick={generateImage}
            >
              <Arrow className={styles.arrow} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateAiImage);
