import clsx from 'clsx';
import { generateNewIndex } from 'Pages/PageGenerationEditor/Draggable/utils';
import { ReactComponent as ImageIcon } from 'Assets/icons/rounded-image.svg';
import { ReactComponent as BlankPage } from 'Assets/icons/blankPage.svg';

import styles from './EmptyTemplates.module.scss';

export const emptyTemplates = [
  { name: 'Empty', id: '67abf0918ad904abbd482637', value: [], isEmpty: true },
  {
    name: 'Message',
    padding: 25,
    id: '672a055e980031f7267c142d',
    value: [
      {
        type: 'title',
        width: '100%',
      },
      {
        type: 'title',
        width: '60%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
      {
        type: 'text',
        width: '100%',
      },
    ],
  },
  {
    id: '672a0016980031f7267c071a',
    name: 'Promotion',
    padding: 25,
    data: [],
    value: [
      {
        type: 'image',
        width: '100%',
        height: '50px',
      },
      {
        type: 'title',
        width: '60%',
      },
      { type: 'text', width: '100%' },
      { type: 'text', width: '90%' },
      { type: 'text', width: '75%' },
      { type: 'text', width: '100%' },
      { type: 'text', width: '100%' },
      { type: 'button', align: 'center' },
    ],
  },
  // {
  //   id: '6729d6e4980031f7267ba7ab',
  //   name: 'About',
  //   padding: 25,
  //   data: [],
  //   value: [
  //     { type: 'title', width: '100%' },
  //     { type: 'title', width: '60%' },
  //     { type: 'image', width: '100%', height: '60px' },
  //     { type: 'text', width: '100%' },
  //     { type: 'text', width: '100%' },
  //     { type: 'text', width: '100%' },
  //     { type: 'text', width: '100%' },
  //     { type: 'text', width: '100%' },
  //   ],
  // },
  // {
  //   id: '672a073d980031f7267c17ce',
  //   name: 'Products',
  //   padding: '25px 45px',
  //   data: [],
  //   value: [
  //     {
  //       type: 'image',
  //       width: '100%',
  //       height: '35px',
  //     },
  //     { type: 'text', width: '100%' },
  //     { type: 'text', width: '90%' },
  //     { type: 'button', align: 'flex-start' },
  //     {
  //       type: 'image',
  //       width: '100%',
  //       height: '35px',
  //     },
  //     { type: 'text', width: '100%' },
  //     { type: 'text', width: '90%' },
  //     { type: 'button', align: 'flex-start' },
  //   ],
  // },
  // {
  //   id: '672a0d1c980031f7267c2695',
  //   name: 'News',
  //   padding: 25,
  //   data: [],
  //   value: [
  //     { type: 'image', width: '100%', height: '50px' },
  //     { type: 'text', width: '80%' },
  //     { type: 'text', width: '90%' },
  //     { type: 'image', width: '100%', height: '50px' },
  //     { type: 'text', width: '50%' },
  //     { type: 'text', width: '100%' },
  //   ],
  // },
];

interface IProps {
  handleSelect: (id: string, isEmpty?: boolean) => void;
  selectedTemplate?: string;
  className?: string;
}

const EmptyTemplates = ({
  handleSelect,
  selectedTemplate,
  className,
}: IProps) => {
  return (
    <div className={clsx(styles.templates, className)}>
      {emptyTemplates.map((template) => (
        <div
          className={clsx(styles.templateItem, {
            [styles.active]: selectedTemplate === template.id,
          })}
          onClick={() => {
            if (selectedTemplate !== template.id) {
              handleSelect(template.id, template.isEmpty);
            }
          }}
        >
          <div
            className={styles.emptyTemplateContent}
            style={{ padding: template.padding }}
          >
            {template.value.length ? (
              <div className={styles.templateItems}>
                {template.value.map((item) =>
                  item.type === 'title' ? (
                    <div
                      className={styles.emptyTitle}
                      style={{ width: item.width }}
                    />
                  ) : item.type === 'text' ? (
                    <div
                      className={styles.emptyText}
                      style={{ width: item.width }}
                    />
                  ) : item.type === 'image' ? (
                    <div
                      className={styles.emptyImage}
                      style={{
                        height: (item as { height: string }).height,
                        width: item.width,
                      }}
                    >
                      <ImageIcon className={styles.imageIcon} />
                    </div>
                  ) : item.type === 'button' ? (
                    <div
                      className={styles.emptyButtonContainer}
                      style={{
                        justifyContent: (item as { align: string }).align,
                      }}
                    >
                      <div className={styles.emptyButton}>
                        <div className={styles.emptyButtonText} />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
            ) : (
              <div className={styles.blankTemplate}>
                <BlankPage />
              </div>
            )}
          </div>
          <div className={styles.templateName}>{template.name}</div>
        </div>
      ))}
    </div>
  );
};

export default EmptyTemplates;
