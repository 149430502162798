import { useMemo } from 'react';
import clsx from 'clsx';
import { ReactComponent as Arrow } from 'Assets/icons/arrowTiny.svg';

import s from './Pagination.module.scss';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const pages = useMemo(() => {
    const pagesArray = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pagesArray.push(i);
      }
    } else {
      pagesArray.push(1);
      if (currentPage <= 3) {
        pagesArray.push(2);
        pagesArray.push(3);
        pagesArray.push(4);
        pagesArray.push('...');
      } else if (currentPage >= totalPages - 2) {
        pagesArray.push('...');
        pagesArray.push(totalPages - 3);
        pagesArray.push(totalPages - 2);
        pagesArray.push(totalPages - 1);
      } else {
        pagesArray.push('...');
        pagesArray.push(currentPage - 1);
        pagesArray.push(currentPage);
        pagesArray.push(currentPage + 1);
        pagesArray.push('...');
      }
      pagesArray.push(totalPages);
    }
    return pagesArray;
  }, [totalPages, currentPage]);

  if (totalPages < 2) {
    return null;
  }

  return (
    <div className={s.paginationContainer}>
      <button
        className={clsx(
          { [s.disableButton]: currentPage === 1 || !totalPages },
          s.pageButton
        )}
        disabled={currentPage === 1 || !totalPages}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <Arrow style={{ transform: 'rotate(180deg)' }} className={s.arrow} />
      </button>
      {pages.map((page, index) =>
        page === '...' ? (
          <span key={index} className={s.pageNumber}>
            {page}
          </span>
        ) : (
          <button
            key={index}
            className={clsx(s.pageNumber, {
              [s.currentPage]: currentPage === page,
            })}
            onClick={() => onPageChange(page as number)}
          >
            {page}
          </button>
        )
      )}
      <button
        className={clsx(s.pageButton, {
          [s.disableButton]: currentPage === totalPages || !totalPages,
        })}
        disabled={currentPage === totalPages || !totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <Arrow className={s.arrow} />
      </button>
    </div>
  );
};

export default Pagination;
