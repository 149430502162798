export const UPDATE_SMART_LIST = {
  operationName: 'updateSmartList',
  query: `mutation updateSmartList(
        <KEYS>
      ) {
      updateSmartList(
          <VALUES>
      ) {
        _id
        name
        exact {
          _id
          name
        }
        includeAll {
          _id
          name
        }
        includeAny {
          _id
          name
        }
        excludeAll {
          _id
          name
        }
        maxContactAge
        createdAt
      }
    }`,
};
