import { useRef, useState } from 'react';
import { ReactComponent as RemoveIcon } from 'Assets/icons/remove-icon.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import CustomDropdown from 'Components/CustomDropdown/CustomDropdown';
import Button from 'UILib/Button/Button';
import Input from 'UILib/Input/Input';

import styles from './UserInputDropdown.module.scss';

interface IProps {
  dropdownLabel: string;
  options: string[];
  selectedOption?: string;
  onOptionAdd: (option: string) => void;
  onOptionRemove: (option: string) => void;
  onOptionSelect: (option: string) => void;
}

const UserInputDropdown = ({
  dropdownLabel,
  options,
  selectedOption,
  onOptionAdd,
  onOptionRemove,
  onOptionSelect,
}: IProps) => {
  const [closeDropdown, setCloseDropdown] = useState(true);
  const [newOption, setNewOption] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <CustomDropdown
          size="small"
          options={options.map((option, index) => ({
            label: (
              <div className={styles.option} key={option + `_${index}`}>
                {option}
                <RemoveIcon
                  className={styles.removeIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onOptionRemove(option);
                  }}
                />
              </div>
            ),
            value: option,
          }))}
          close={closeDropdown}
          setClose={setCloseDropdown}
          value={selectedOption}
          className={styles.dropdown}
          optionsClassName={styles.dropdownOptions}
          label={dropdownLabel}
          onChange={(newValue) => {
            onOptionSelect(newValue);
          }}
          footer={
            <div className={styles.addOption}>
              <Input
                border="stroke"
                placeholder="Type to add"
                value={newOption}
                className={styles.input}
                onChange={(e) => setNewOption(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && newOption) {
                    if (!!newOption) {
                      onOptionAdd(newOption);
                      onOptionSelect(newOption);
                      setCloseDropdown(true);
                    }
                    setNewOption('');
                  }
                }}
                postfixIcon={
                  <Button
                    width={30}
                    height={30}
                    className={styles.addButton}
                    appearance="highlighted"
                    prefixIcon={<PlusIcon />}
                    onClick={() => {
                      if (!!newOption) {
                        onOptionAdd(newOption);
                        onOptionSelect(newOption);
                        setCloseDropdown(true);
                      }
                      setNewOption('');
                    }}
                  />
                }
              />
            </div>
          }
        />
      </div>
    </>
  );
};

export default UserInputDropdown;
