export const GET_BROADCASTS_WITH_PAGINATION_QUERY = {
  operationName: 'getBroadcastsWithPagination',
  query: `query getBroadcastsWithPagination ($skip: Float!, $limit: Float!, $search: String, $sortBy: String, $sortAsc: Boolean) {
      getBroadcastsWithPagination(skip: $skip, limit: $limit, search: $search, sortBy: $sortBy, sortAsc: $sortAsc) {
        broadcasts {
            _id
            name
            smartList { 
              _id
              name 
            }
            workflow { _id }
            sender { 
              _id
              name
            }
            mailDraft { 
              _id
              generationTask{
                _id
                status
              }
            }
            image
            sendAt
            nextSendAt
            status
            createdAt
            updatedAt
        }
        total
      }
    }`,
};
